.home-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.home-navbar {
  top: 0px;
  left: 0px;
  width: 1440px;
  height: 72px;
  display: flex;
  padding: 0 64px;
  position: absolute;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-default-white);
}
.home-container1 {
  gap: 32px;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  justify-content: space-between;
}
.home-frame47 {
  gap: 12px;
  display: flex;
  align-items: center;
}
.home-group3 {
  width: 29px;
  height: 29px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 1;
}
.home-ellipse8 {
  top: 0px;
  left: 0px;
  width: 24px;
  height: 24px;
  position: absolute;
}
.home-ellipse9 {
  top: 4.971428394317627px;
  left: 4.971428394317627px;
  width: 24px;
  height: 24px;
  position: absolute;
}
.home-text {
  color: rgba(20, 20, 20, 1);
  height: auto;
  font-size: 24px;
  font-style: Regular;
  text-align: left;
  font-family: DM Sans;
  font-weight: 400;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.home-column {
  gap: 48px;
  display: flex;
  overflow: hidden;
  align-items: flex-start;
}
.home-text01 {
  color: rgba(20, 20, 20, 1);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: DM Sans;
  font-weight: 400;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.home-text02 {
  color: rgba(20, 20, 20, 1);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: DM Sans;
  font-weight: 400;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.home-text03 {
  color: rgba(20, 20, 20, 1);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: DM Sans;
  font-weight: 400;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.home-text04 {
  color: rgba(20, 20, 20, 1);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: DM Sans;
  font-weight: 400;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.home-column1 {
  gap: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-button {
  gap: 16px;
  display: flex;
  padding: 8px 20px;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.029999999329447746) ;
  align-items: center;
  border-color: rgba(20, 20, 20, 0.10000000149011612);
  border-style: solid;
  border-width: 1px;
  border-radius: 6px;
  justify-content: center;
  background-color: rgba(255, 255, 255, 1);
}
.home-text05 {
  color: rgba(20, 20, 20, 1);
  height: auto;
  font-size: 16px;
  font-style: Medium;
  text-align: left;
  font-family: DM Sans;
  font-weight: 500;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.home-frame11 {
  gap: 8px;
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-hero {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  position: relative;
  min-height: 80vh;
  align-items: center;
  flex-direction: column;
  background-size: cover;
  justify-content: center;
  background-image: linear-gradient(113.8deg, rgba(255, 76, 92, 0.8) 7.5%, rgba(255, 177, 70, 0.8) 94.47%),url('https://images.unsplash.com/photo-1454165804606-c3d57bc86b40?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDIzfHx3b3JrJTIwbGFwdG9wfGVufDB8fHx8MTYyNjE3NjkzMA&ixlib=rb-1.2.1&w=1500');
}
.home-text07 {
  color: var(--dl-color-gray-white);
  font-size: 3rem;
}
.home-text08 {
  color: var(--dl-color-gray-white);
  margin-top: var(--dl-space-space-twounits);
  margin-bottom: var(--dl-space-space-twounits);
}
.home-btn-group {
  display: flex;
  align-items: center;
  flex-direction: row;
}
.home-button1 {
  font-size: 0.75rem;
  transition: 0.3s;
  padding-top: var(--dl-space-space-unit);
  border-width: 0px;
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-unit);
  text-transform: uppercase;
}
.home-button1:hover {
  transform: scale(1.02);
}
.home-button2 {
  color: var(--dl-color-gray-white);
  font-size: 0.75rem;
  transition: 0.3s;
  margin-left: var(--dl-space-space-unit);
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-white);
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-unit);
  text-transform: uppercase;
  background-color: transparent;
}
.home-button2:hover {
  transform: scale(1.02);
}
.home-container2 {
  flex: 0 0 auto;
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-footer {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: space-between;
}
.home-image {
  height: 2rem;
  object-fit: cover;
}
.home-icon-group {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-icon {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.home-icon2 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.home-icon4 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
@media(max-width: 1600px) {
  .home-navbar {
    top: 0px;
    flex: 1;
    left: 0px;
    right: 0px;
    width: 1600px;
    height: 72dium;
    margin: auto;
    border-color: var(--dl-color-gray-black);
    border-width: 1px;
    background-color: #181818;
  }
  .home-container1 {
    align-self: stretch;
  }
  .home-text {
    color: #ffffff;
    font-size: 24px;
    text-align: center;
    font-family: DM Sans;
    font-weight: bold;
    line-height: 150%;
    text-transform: capitalize;
    text-decoration: none;
  }
  .home-text01 {
    color: #ffffff;
    font-size: 18px;
    text-align: center;
    font-family: DM Sans;
    line-height: 150%;
    text-transform: capitalize;
    text-decoration: none;
  }
  .home-text02 {
    color: #ffffff;
    font-size: 18px;
    text-align: center;
    font-family: DM Sans;
    font-weight: 400;
    line-height: 150%;
    text-transform: capitalize;
    text-decoration: none;
  }
  .home-text03 {
    color: #ffffff;
    font-size: 18px;
    text-align: center;
    text-transform: capitalize;
  }
  .home-text04 {
    color: #ffffff;
    font-size: 18px;
    text-align: center;
    font-family: DM Sans;
    font-weight: 400;
    line-height: 150%;
    text-transform: capitalize;
    text-decoration: none;
  }
  .home-button {
    background-color: #3d3d3d;
  }
  .home-text05 {
    color: #ffffff;
    font-size: 18px;
    text-align: center;
    text-transform: capitalize;
  }
  .home-hero {
    height: 100%;
    padding: 0px;
    margin-top: var(--dl-space-space-fourunits);
    background-image: linear-gradient(to right, #fc00ff 0%, #00dbde 100%),url('https://images.unsplash.com/photo-1454165804606-c3d57bc86b40?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDIzfHx3b3JrJTIwbGFwdG9wfGVufDB8fHx8MTYyNjE3NjkzMA&ixlib=rb-1.2.1&w=1500');
  }
  .home-text07 {
    color: var(--dl-color-gray-white);
    font-size: 3rem;
  }
  .home-button1 {
    font-size: 0.75rem;
    text-transform: uppercase;
  }
  .home-container2 {
    left: 0px;
    bottom: 0px;
    position: absolute;
  }
}
@media(max-width: 1200px) {
  .home-navbar {
    flex: 1;
    width: 1200px;
  }
}
@media(max-width: 991px) {
  .home-hero {
    flex-direction: column;
  }
  .home-text07 {
    text-align: center;
  }
  .home-text08 {
    text-align: center;
  }
}
@media(max-width: 767px) {
  .home-hero {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-footer {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-text15 {
    text-align: center;
    margin-left: var(--dl-space-space-unit);
    margin-right: var(--dl-space-space-unit);
  }
}
@media(max-width: 479px) {
  .home-hero {
    width: 100%;
    padding: var(--dl-space-space-twounits);
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .home-btn-group {
    flex-direction: column;
  }
  .home-button2 {
    margin-top: var(--dl-space-space-unit);
    margin-left: 0px;
  }
  .home-footer {
    padding: var(--dl-space-space-unit);
    flex-direction: column;
  }
  .home-image {
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-text15 {
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-unit);
  }
}
