.about-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.about-navbar {
  top: 0px;
  left: 0px;
  width: 1440px;
  height: 72px;
  display: flex;
  padding: 0 64px;
  position: absolute;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-default-white);
}
.about-container1 {
  gap: 32px;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  justify-content: space-between;
}
.about-frame47 {
  gap: 12px;
  display: flex;
  align-items: center;
}
.about-group3 {
  width: 29px;
  height: 29px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 1;
}
.about-ellipse8 {
  top: 0px;
  left: 0px;
  width: 24px;
  height: 24px;
  position: absolute;
}
.about-ellipse9 {
  top: 4.971428394317627px;
  left: 4.971428394317627px;
  width: 24px;
  height: 24px;
  position: absolute;
}
.about-text {
  color: rgba(20, 20, 20, 1);
  height: auto;
  font-size: 24px;
  font-style: Regular;
  text-align: left;
  font-family: DM Sans;
  font-weight: 400;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.about-column {
  gap: 48px;
  display: flex;
  overflow: hidden;
  align-items: flex-start;
}
.about-text01 {
  color: rgba(20, 20, 20, 1);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: DM Sans;
  font-weight: 400;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.about-text02 {
  color: rgba(20, 20, 20, 1);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: DM Sans;
  font-weight: 400;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.about-text03 {
  color: rgba(20, 20, 20, 1);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: DM Sans;
  font-weight: 400;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.about-text04 {
  color: rgba(20, 20, 20, 1);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: DM Sans;
  font-weight: 400;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.about-column1 {
  gap: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.about-button {
  gap: 16px;
  display: flex;
  padding: 8px 20px;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.029999999329447746) ;
  align-items: center;
  border-color: rgba(20, 20, 20, 0.10000000149011612);
  border-style: solid;
  border-width: 1px;
  border-radius: 6px;
  justify-content: center;
  background-color: rgba(255, 255, 255, 1);
}
.about-text05 {
  color: rgba(20, 20, 20, 1);
  height: auto;
  font-size: 16px;
  font-style: Medium;
  text-align: left;
  font-family: DM Sans;
  font-weight: 500;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.about-button1 {
  gap: 8px;
  display: flex;
  padding: 8px 20px;
  box-sizing: content-box;
  align-items: center;
  border-radius: 4px;
  justify-content: center;
  background-color: rgba(53, 117, 226, 1);
}
.about-text07 {
  color: var(--dl-color-default-white);
  height: auto;
  font-size: 16px;
  font-style: Medium;
  text-align: left;
  font-family: DM Sans;
  font-weight: 500;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.about-frame11 {
  gap: 8px;
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.about-container2 {
  flex: 0 0 auto;
  width: 100%;
  height: 100px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.about-footer {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: space-between;
}
.about-image {
  height: 2rem;
  object-fit: cover;
}
.about-icon-group {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.about-icon {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.about-icon2 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.about-icon4 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.about-banner {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}
.about-features {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  max-width: var(--dl-size-size-maxwidth);
  flex-direction: column;
}
.about-features1 {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  max-width: var(--dl-size-size-maxwidth);
  flex-direction: column;
}
.about-text11 {
  font-size: 3rem;
  margin-bottom: var(--dl-space-space-threeunits);
}
.about-container3 {
  width: 100%;
  display: grid;
  grid-gap: var(--dl-space-space-twounits);
  grid-template-columns: 1fr 1fr;
}
.about-container4 {
  width: 100%;
  display: grid;
  grid-gap: var(--dl-space-space-twounits);
  grid-template-columns: 1fr 1fr;
}
@media(max-width: 1600px) {
  .about-container {
    background-color: #ffffff;
  }
  .about-navbar {
    top: 0px;
    flex: 1;
    left: 0px;
    right: 0px;
    width: 1600px;
    height: 72dium;
    margin: auto;
    background-color: #212121;
  }
  .about-container1 {
    align-self: stretch;
  }
  .about-text {
    color: #ffffff;
    font-size: 24px;
    text-align: center;
    font-family: DM Sans;
    font-weight: bold;
    line-height: 150%;
    text-transform: capitalize;
    text-decoration: none;
  }
  .about-text01 {
    color: #ffffff;
    font-size: 18px;
    text-align: center;
    font-family: DM Sans;
    font-weight: 400;
    line-height: 150%;
    text-transform: capitalize;
    text-decoration: none;
  }
  .about-text02 {
    color: #ffffff;
    font-size: 18px;
    text-align: center;
    font-family: DM Sans;
    font-weight: 400;
    line-height: 150%;
    text-transform: capitalize;
    text-decoration: none;
  }
  .about-text03 {
    color: #ffffff;
    font-size: 18px;
    text-align: center;
    border-color: #000000;
    border-width: 1px;
    border-radius: var(--dl-radius-radius-radius4);
    text-transform: capitalize;
  }
  .about-text04 {
    color: #ffffff;
    font-size: 18px;
    text-align: center;
    font-family: DM Sans;
    font-weight: 400;
    line-height: 150%;
    text-transform: capitalize;
    text-decoration: none;
  }
  .about-button {
    background-color: #3d3d3d;
  }
  .about-text05 {
    color: #ffffff;
    font-size: 18px;
    text-align: center;
    text-transform: capitalize;
  }
  .about-text07 {
    font-size: 18px;
    text-align: center;
    font-weight: bold;
    text-transform: capitalize;
  }
  .about-container2 {
    left: 0px;
    bottom: 0px;
    position: absolute;
  }
  .about-image {
    border-radius: var(--dl-radius-radius-radius4);
  }
  .about-text09 {
    color: #000000;
    border-radius: var(--dl-radius-radius-radius4);
  }
  .about-banner {
    height: 314px;
    margin-top: var(--dl-space-space-fourunits);
    background-image: linear-gradient(to right, #c2e59c 0%, #64b3f4 100%);
  }
  .about-text10 {
    color: var(--dl-color-gray-black);
    font-size: 24px;
    margin-top: var(--dl-space-space-twounits);
    text-align: center;
    line-height: 1.5;
    margin-left: var(--dl-space-space-twounits);
    margin-right: var(--dl-space-space-twounits);
  }
  .about-text11 {
    text-align: center;
  }
}
@media(max-width: 1200px) {
  .about-navbar {
    flex: 1;
    width: 1200px;
  }
}
@media(max-width: 767px) {
  .about-footer {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .about-text09 {
    text-align: center;
    margin-left: var(--dl-space-space-unit);
    margin-right: var(--dl-space-space-unit);
  }
  .about-banner {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .about-features {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .about-features1 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .about-container3 {
    grid-template-columns: 1fr;
  }
  .about-container4 {
    grid-template-columns: 1fr;
  }
}
@media(max-width: 479px) {
  .about-footer {
    padding: var(--dl-space-space-unit);
    flex-direction: column;
  }
  .about-image {
    margin-bottom: var(--dl-space-space-unit);
  }
  .about-text09 {
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-unit);
  }
  .about-banner {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .about-features {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .about-features1 {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
}
