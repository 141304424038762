.mockup-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.mockup-jobofferplatform {
  width: 100%;
  height: 2049px;
  display: flex;
  overflow: hidden;
  position: relative;
  box-sizing: content-box;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(20, 20, 20, 0.10000000149011612);
  border-style: solid;
  border-width: 2px;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 1);
}
.mockup-content {
  gap: 8px;
  top: 451px;
  left: 0px;
  width: 1440px;
  display: flex;
  padding: 48px 64px 64px;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
  background-color: rgba(250, 250, 250, 1);
}
.mockup-frame15 {
  gap: 32px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.mockup-filter {
  gap: 24px;
  width: 250px;
  display: flex;
  padding: 24px;
  overflow: hidden;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.029999999329447746) ;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(20, 20, 20, 0.05000000074505806);
  border-style: solid;
  border-width: 1px;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 1);
}
.mockup-frame36 {
  gap: 16px;
  width: 202px;
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.mockup-text {
  color: rgba(20, 20, 20, 1);
  height: auto;
  font-size: 20px;
  font-style: Bold;
  text-align: left;
  font-family: DM Sans;
  font-weight: 700;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.mockup-frame35 {
  gap: 12px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.mockup-text002 {
  color: rgba(20, 20, 20, 1);
  height: auto;
  font-size: 18px;
  align-self: stretch;
  font-style: Medium;
  text-align: left;
  font-family: DM Sans;
  font-weight: 500;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.mockup-frame34 {
  gap: 8px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.mockup-frame31 {
  gap: 8px;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
}
.mockup-ellipse5 {
  width: 16px;
  height: 16px;
  border-color: rgba(20, 20, 20, 0.30000001192092896);
  border-style: solid;
  border-width: 1px;
}
.mockup-text004 {
  color: rgba(57, 63, 71, 1);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: DM Sans;
  font-weight: 400;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.mockup-frame32 {
  gap: 8px;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
}
.mockup-group1 {
  width: 16px;
  height: 16px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 1;
}
.mockup-ellipse6 {
  top: 0px;
  left: 0px;
  width: 16px;
  height: 16px;
  position: absolute;
  box-sizing: content-box;
}
.mockup-ellipse7 {
  top: 4px;
  left: 4px;
  width: 8px;
  height: 8px;
  position: absolute;
}
.mockup-text006 {
  color: rgba(57, 63, 71, 1);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: DM Sans;
  font-weight: 400;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.mockup-frame33 {
  gap: 8px;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
}
.mockup-ellipse501 {
  width: 16px;
  height: 16px;
  border-color: rgba(20, 20, 20, 0.30000001192092896);
  border-style: solid;
  border-width: 1px;
}
.mockup-text008 {
  color: rgba(57, 63, 71, 1);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: DM Sans;
  font-weight: 400;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.mockup-frame341 {
  gap: 8px;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
}
.mockup-ellipse502 {
  width: 16px;
  height: 16px;
  border-color: rgba(20, 20, 20, 0.30000001192092896);
  border-style: solid;
  border-width: 1px;
}
.mockup-text010 {
  color: rgba(57, 63, 71, 1);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: DM Sans;
  font-weight: 400;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.mockup-frame351 {
  gap: 8px;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
}
.mockup-ellipse503 {
  width: 16px;
  height: 16px;
  border-color: rgba(20, 20, 20, 0.30000001192092896);
  border-style: solid;
  border-width: 1px;
}
.mockup-text012 {
  color: rgba(57, 63, 71, 1);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: DM Sans;
  font-weight: 400;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.mockup-frame361 {
  gap: 8px;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
}
.mockup-ellipse504 {
  width: 16px;
  height: 16px;
  border-color: rgba(20, 20, 20, 0.30000001192092896);
  border-style: solid;
  border-width: 1px;
}
.mockup-text014 {
  color: rgba(57, 63, 71, 1);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: DM Sans;
  font-weight: 400;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.mockup-frame37 {
  gap: 12px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.mockup-text016 {
  color: rgba(20, 20, 20, 1);
  height: auto;
  font-size: 18px;
  align-self: stretch;
  font-style: Medium;
  text-align: left;
  font-family: DM Sans;
  font-weight: 500;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.mockup-frame43 {
  gap: -1px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.mockup-frame40 {
  gap: 8px;
  width: 68px;
  display: flex;
  padding: 8px;
  overflow: hidden;
  flex-grow: 1;
  align-items: center;
  flex-shrink: 0;
  border-color: rgba(20, 20, 20, 0.10000000149011612);
  border-style: solid;
  border-width: 1px;
  border-radius: 2px 0 0 2px;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(255, 255, 255, 1);
}
.mockup-text018 {
  color: rgba(57, 63, 71, 1);
  height: auto;
  font-size: 13px;
  font-style: Regular;
  text-align: left;
  font-family: DM Sans;
  font-weight: 400;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.mockup-frame41 {
  gap: 8px;
  width: 68px;
  display: flex;
  padding: 8px;
  overflow: hidden;
  flex-grow: 1;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(20, 20, 20, 0.10000000149011612);
  border-style: solid;
  border-width: 1px;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 1);
}
.mockup-text020 {
  color: rgba(57, 63, 71, 1);
  height: auto;
  font-size: 13px;
  font-style: Regular;
  text-align: left;
  font-family: DM Sans;
  font-weight: 400;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.mockup-frame42 {
  gap: 8px;
  width: 68px;
  display: flex;
  padding: 8px;
  overflow: hidden;
  flex-grow: 1;
  align-items: center;
  flex-shrink: 0;
  border-color: rgba(53, 117, 226, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 0 2px 2px 0;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(53, 117, 226, 0.20000000298023224);
}
.mockup-text022 {
  color: rgba(53, 117, 226, 1);
  height: auto;
  font-size: 13px;
  font-style: Medium;
  text-align: left;
  font-family: DM Sans;
  font-weight: 500;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.mockup-frame342 {
  gap: 8px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.mockup-frame311 {
  gap: 8px;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
}
.mockup-group11 {
  width: 16px;
  height: 16px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 1;
}
.mockup-ellipse61 {
  top: 0px;
  left: 0px;
  width: 16px;
  height: 16px;
  position: absolute;
  box-sizing: content-box;
}
.mockup-ellipse71 {
  top: 4px;
  left: 4px;
  width: 8px;
  height: 8px;
  position: absolute;
}
.mockup-text024 {
  color: rgba(57, 63, 71, 1);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: DM Sans;
  font-weight: 400;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.mockup-frame321 {
  gap: 8px;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
}
.mockup-ellipse505 {
  width: 16px;
  height: 16px;
  border-color: rgba(20, 20, 20, 0.30000001192092896);
  border-style: solid;
  border-width: 1px;
}
.mockup-text026 {
  color: rgba(57, 63, 71, 1);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: DM Sans;
  font-weight: 400;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.mockup-frame331 {
  gap: 8px;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
}
.mockup-ellipse506 {
  width: 16px;
  height: 16px;
  border-color: rgba(20, 20, 20, 0.30000001192092896);
  border-style: solid;
  border-width: 1px;
}
.mockup-text028 {
  color: rgba(57, 63, 71, 1);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: DM Sans;
  font-weight: 400;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.mockup-frame343 {
  gap: 8px;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
}
.mockup-ellipse507 {
  width: 16px;
  height: 16px;
  border-color: rgba(20, 20, 20, 0.30000001192092896);
  border-style: solid;
  border-width: 1px;
}
.mockup-text030 {
  color: rgba(57, 63, 71, 1);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: DM Sans;
  font-weight: 400;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.mockup-frame352 {
  gap: 8px;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
}
.mockup-ellipse508 {
  width: 16px;
  height: 16px;
  border-color: rgba(20, 20, 20, 0.30000001192092896);
  border-style: solid;
  border-width: 1px;
}
.mockup-text032 {
  color: rgba(57, 63, 71, 1);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: DM Sans;
  font-weight: 400;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.mockup-frame362 {
  gap: 12px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.mockup-text034 {
  color: rgba(20, 20, 20, 1);
  height: auto;
  font-size: 18px;
  align-self: stretch;
  font-style: Medium;
  text-align: left;
  font-family: DM Sans;
  font-weight: 500;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.mockup-frame344 {
  gap: 8px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.mockup-frame312 {
  gap: 8px;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
}
.mockup-group12 {
  width: 16px;
  height: 16px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 1;
}
.mockup-ellipse62 {
  top: 0px;
  left: 0px;
  width: 16px;
  height: 16px;
  position: absolute;
  box-sizing: content-box;
}
.mockup-ellipse72 {
  top: 4px;
  left: 4px;
  width: 8px;
  height: 8px;
  position: absolute;
}
.mockup-text036 {
  color: rgba(57, 63, 71, 1);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: DM Sans;
  font-weight: 400;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.mockup-frame322 {
  gap: 8px;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
}
.mockup-ellipse509 {
  width: 16px;
  height: 16px;
  border-color: rgba(20, 20, 20, 0.30000001192092896);
  border-style: solid;
  border-width: 1px;
}
.mockup-text038 {
  color: rgba(57, 63, 71, 1);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: DM Sans;
  font-weight: 400;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.mockup-frame332 {
  gap: 8px;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
}
.mockup-ellipse510 {
  width: 16px;
  height: 16px;
  border-color: rgba(20, 20, 20, 0.30000001192092896);
  border-style: solid;
  border-width: 1px;
}
.mockup-text040 {
  color: rgba(57, 63, 71, 1);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: DM Sans;
  font-weight: 400;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.mockup-frame345 {
  gap: 8px;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
}
.mockup-ellipse511 {
  width: 16px;
  height: 16px;
  border-color: rgba(20, 20, 20, 0.30000001192092896);
  border-style: solid;
  border-width: 1px;
}
.mockup-text042 {
  color: rgba(57, 63, 71, 1);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: DM Sans;
  font-weight: 400;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.mockup-frame38 {
  gap: 12px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.mockup-text044 {
  color: rgba(20, 20, 20, 1);
  height: auto;
  font-size: 18px;
  align-self: stretch;
  font-style: Medium;
  text-align: left;
  font-family: DM Sans;
  font-weight: 500;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.mockup-frame346 {
  gap: 8px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.mockup-frame313 {
  gap: 8px;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
}
.mockup-group13 {
  width: 16px;
  height: 16px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 1;
}
.mockup-ellipse63 {
  top: 0px;
  left: 0px;
  width: 16px;
  height: 16px;
  position: absolute;
  box-sizing: content-box;
}
.mockup-ellipse73 {
  top: 4px;
  left: 4px;
  width: 8px;
  height: 8px;
  position: absolute;
}
.mockup-text046 {
  color: rgba(57, 63, 71, 1);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: DM Sans;
  font-weight: 400;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.mockup-frame323 {
  gap: 8px;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
}
.mockup-ellipse512 {
  width: 16px;
  height: 16px;
  border-color: rgba(20, 20, 20, 0.30000001192092896);
  border-style: solid;
  border-width: 1px;
}
.mockup-text048 {
  color: rgba(57, 63, 71, 1);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: DM Sans;
  font-weight: 400;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.mockup-frame333 {
  gap: 8px;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
}
.mockup-ellipse513 {
  width: 16px;
  height: 16px;
  border-color: rgba(20, 20, 20, 0.30000001192092896);
  border-style: solid;
  border-width: 1px;
}
.mockup-text050 {
  color: rgba(57, 63, 71, 1);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: DM Sans;
  font-weight: 400;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.mockup-frame39 {
  gap: 12px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.mockup-text052 {
  color: rgba(20, 20, 20, 1);
  height: auto;
  font-size: 18px;
  align-self: stretch;
  font-style: Medium;
  text-align: left;
  font-family: DM Sans;
  font-weight: 500;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.mockup-frame347 {
  gap: 8px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.mockup-frame314 {
  gap: 8px;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
}
.mockup-group4 {
  width: 16px;
  height: 16.0078125px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 1;
}
.mockup-rectangle2 {
  top: 0px;
  left: 0px;
  width: 16px;
  height: 16px;
  position: absolute;
  border-radius: 2px;
}
.mockup-check {
  top: 0.0078125px;
  left: 0px;
  width: 16px;
  height: 16px;
  position: absolute;
}
.mockup-text054 {
  color: rgba(57, 63, 71, 1);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: DM Sans;
  font-weight: 400;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.mockup-frame324 {
  gap: 8px;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
}
.mockup-rectangle21 {
  width: 16px;
  height: 16px;
  border-color: rgba(20, 20, 20, 0.30000001192092896);
  border-style: solid;
  border-width: 1px;
  border-radius: 2px;
}
.mockup-text056 {
  color: rgba(57, 63, 71, 1);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: DM Sans;
  font-weight: 400;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.mockup-frame334 {
  gap: 8px;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
}
.mockup-rectangle3 {
  width: 16px;
  height: 16px;
  border-color: rgba(20, 20, 20, 0.30000001192092896);
  border-style: solid;
  border-width: 1px;
  border-radius: 2px;
}
.mockup-text058 {
  color: rgba(57, 63, 71, 1);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: DM Sans;
  font-weight: 400;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.mockup-cards {
  gap: 48px;
  width: 748px;
  display: flex;
  flex-grow: 1;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: flex-end;
}
.mockup-frame71 {
  gap: 16px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.mockup-frame46 {
  gap: 16px;
  width: 748px;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  justify-content: space-between;
}
.mockup-text060 {
  color: rgba(20, 20, 20, 1);
  height: auto;
  font-size: 32px;
  font-style: Bold;
  text-align: left;
  font-family: DM Sans;
  font-weight: 700;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.mockup-frame14 {
  gap: 16px;
  width: 281px;
  display: flex;
  padding: 12px;
  overflow: hidden;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.029999999329447746) ;
  align-items: center;
  flex-shrink: 0;
  border-color: rgba(20, 20, 20, 0.10000000149011612);
  border-style: solid;
  border-width: 1px;
  border-radius: 4px;
  justify-content: space-between;
  background-color: rgba(255, 255, 255, 1);
}
.mockup-frame45 {
  gap: 12px;
  display: flex;
  align-items: flex-start;
}
.mockup-funnel-simple {
  width: 24px;
  height: 24px;
}
.mockup-text062 {
  color: rgba(20, 20, 20, 1);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: DM Sans;
  font-weight: 400;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.mockup-caret-down {
  width: 16px;
  height: 16px;
}
.mockup-frame26 {
  gap: 24px;
  width: 748px;
  display: flex;
  padding: 24px;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.029999999329447746) ;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(20, 20, 20, 0.05000000074505806);
  border-style: solid;
  border-width: 1px;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 1);
}
.mockup-frame17 {
  width: 72px;
  height: 72px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: 4px;
  background-size: cover;
  background-image: url("/external/78c65453-bb9d-48e4-8691-3299afd8fa1e-wvyi-200h.png");
}
.mockup-frame23 {
  gap: 12px;
  width: 604px;
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.mockup-frame28 {
  gap: 6px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.mockup-container1 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.mockup-frame29 {
  gap: 4px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.mockup-frame27 {
  gap: 12px;
  display: flex;
  align-items: center;
}
.mockup-text064 {
  color: rgba(20, 20, 20, 1);
  height: auto;
  font-size: 18px;
  font-style: Regular;
  text-align: left;
  font-family: DM Sans;
  font-weight: 400;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.mockup-frame30 {
  gap: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.mockup-text066 {
  color: rgba(20, 20, 20, 1);
  height: auto;
  font-size: 24px;
  font-style: Medium;
  text-align: left;
  font-family: DM Sans;
  font-weight: 500;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.mockup-frame25 {
  gap: 4px;
  display: flex;
  padding: 2px 6px;
  overflow: hidden;
  align-items: center;
  border-radius: 3px;
  background-color: rgba(125, 90, 226, 0.10000000149011612);
}
.mockup-text068 {
  color: rgba(125, 90, 226, 1);
  height: auto;
  font-size: 12px;
  font-style: Medium;
  text-align: left;
  font-family: DM Sans;
  font-weight: 500;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.mockup-icon {
  width: 24px;
  height: 24px;
}
.mockup-frame3001 {
  gap: 24px;
  display: flex;
  padding: 2px 0;
  align-items: center;
  justify-content: center;
}
.mockup-frame18 {
  gap: 6px;
  display: flex;
  align-items: center;
}
.mockup-map-pin-line {
  width: 16px;
  height: 16px;
}
.mockup-text070 {
  color: rgba(20, 20, 20, 0.699999988079071);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: DM Sans;
  font-weight: 400;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.mockup-ellipse3 {
  width: 2px;
  height: 2px;
}
.mockup-frame20 {
  gap: 6px;
  display: flex;
  align-items: center;
}
.mockup-text072 {
  color: rgba(20, 20, 20, 0.699999988079071);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: DM Sans;
  font-weight: 400;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.mockup-ellipse2 {
  width: 2px;
  height: 2px;
}
.mockup-frame21 {
  gap: 6px;
  display: flex;
  align-items: center;
}
.mockup-currency-dollar {
  width: 16px;
  height: 16px;
}
.mockup-text074 {
  color: rgba(20, 20, 20, 0.699999988079071);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: DM Sans;
  font-weight: 400;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.mockup-ellipse1 {
  width: 2px;
  height: 2px;
}
.mockup-frame22 {
  gap: 6px;
  display: flex;
  align-items: center;
}
.mockup-clock {
  width: 16px;
  height: 16px;
}
.mockup-text076 {
  color: rgba(20, 20, 20, 0.699999988079071);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: DM Sans;
  font-weight: 400;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.mockup-frame271 {
  gap: 24px;
  width: 748px;
  display: flex;
  padding: 24px;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.029999999329447746) ;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(20, 20, 20, 0.05000000074505806);
  border-style: solid;
  border-width: 1px;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 1);
}
.mockup-frame171 {
  width: 72px;
  height: 72px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: 4px;
  background-size: cover;
  background-image: url("/external/76e45941-25d0-4c47-b286-55dfa6ceb1a8-gjkd-200h.png");
}
.mockup-frame231 {
  gap: 12px;
  width: 604px;
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.mockup-frame281 {
  gap: 6px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.mockup-frame291 {
  gap: 4px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.mockup-frame272 {
  gap: 12px;
  display: flex;
  align-items: center;
}
.mockup-text078 {
  color: rgba(20, 20, 20, 1);
  height: auto;
  font-size: 18px;
  font-style: Regular;
  text-align: left;
  font-family: DM Sans;
  font-weight: 400;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.mockup-frame3002 {
  gap: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.mockup-text080 {
  color: rgba(20, 20, 20, 1);
  height: auto;
  font-size: 24px;
  font-style: Medium;
  text-align: left;
  font-family: DM Sans;
  font-weight: 500;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.mockup-frame3003 {
  gap: 24px;
  display: flex;
  padding: 2px 0;
  align-items: center;
  justify-content: center;
}
.mockup-frame181 {
  gap: 6px;
  display: flex;
  align-items: center;
}
.mockup-map-pin-line1 {
  width: 16px;
  height: 16px;
}
.mockup-text082 {
  color: rgba(20, 20, 20, 0.699999988079071);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: DM Sans;
  font-weight: 400;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.mockup-ellipse31 {
  width: 2px;
  height: 2px;
}
.mockup-frame201 {
  gap: 6px;
  display: flex;
  align-items: center;
}
.mockup-text084 {
  color: rgba(20, 20, 20, 0.699999988079071);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: DM Sans;
  font-weight: 400;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.mockup-ellipse21 {
  width: 2px;
  height: 2px;
}
.mockup-frame211 {
  gap: 6px;
  display: flex;
  align-items: center;
}
.mockup-currency-dollar1 {
  width: 16px;
  height: 16px;
}
.mockup-text086 {
  color: rgba(20, 20, 20, 0.699999988079071);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: DM Sans;
  font-weight: 400;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.mockup-ellipse11 {
  width: 2px;
  height: 2px;
}
.mockup-frame221 {
  gap: 6px;
  display: flex;
  align-items: center;
}
.mockup-clock1 {
  width: 16px;
  height: 16px;
}
.mockup-text088 {
  color: rgba(20, 20, 20, 0.699999988079071);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: DM Sans;
  font-weight: 400;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.mockup-frame48 {
  gap: 24px;
  width: 748px;
  display: flex;
  padding: 24px;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.029999999329447746) ;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(20, 20, 20, 0.05000000074505806);
  border-style: solid;
  border-width: 1px;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 1);
}
.mockup-frame172 {
  width: 72px;
  height: 72px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: 4px;
  background-size: cover;
  background-image: url("/external/88d29e18-950a-443d-afd7-e92338e7cfe8-xzgc-200h.png");
}
.mockup-frame232 {
  gap: 12px;
  width: 604px;
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.mockup-frame282 {
  gap: 6px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.mockup-frame292 {
  gap: 4px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.mockup-frame273 {
  gap: 12px;
  display: flex;
  align-items: center;
}
.mockup-text089 {
  color: rgba(20, 20, 20, 1);
  height: auto;
  font-size: 18px;
  font-style: Regular;
  text-align: left;
  font-family: DM Sans;
  font-weight: 400;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.mockup-frame3004 {
  gap: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.mockup-text091 {
  color: rgba(20, 20, 20, 1);
  height: auto;
  font-size: 24px;
  font-style: Medium;
  text-align: left;
  font-family: DM Sans;
  font-weight: 500;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.mockup-frame3005 {
  gap: 24px;
  display: flex;
  padding: 2px 0;
  align-items: center;
  justify-content: center;
}
.mockup-frame182 {
  gap: 6px;
  display: flex;
  align-items: center;
}
.mockup-map-pin-line2 {
  width: 16px;
  height: 16px;
}
.mockup-text093 {
  color: rgba(20, 20, 20, 0.699999988079071);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: DM Sans;
  font-weight: 400;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.mockup-ellipse32 {
  width: 2px;
  height: 2px;
}
.mockup-frame202 {
  gap: 6px;
  display: flex;
  align-items: center;
}
.mockup-text095 {
  color: rgba(20, 20, 20, 0.699999988079071);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: DM Sans;
  font-weight: 400;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.mockup-ellipse22 {
  width: 2px;
  height: 2px;
}
.mockup-frame212 {
  gap: 6px;
  display: flex;
  align-items: center;
}
.mockup-currency-dollar2 {
  width: 16px;
  height: 16px;
}
.mockup-text097 {
  color: rgba(20, 20, 20, 0.699999988079071);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: DM Sans;
  font-weight: 400;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.mockup-ellipse12 {
  width: 2px;
  height: 2px;
}
.mockup-frame222 {
  gap: 6px;
  display: flex;
  align-items: center;
}
.mockup-clock2 {
  width: 16px;
  height: 16px;
}
.mockup-text099 {
  color: rgba(20, 20, 20, 0.699999988079071);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: DM Sans;
  font-weight: 400;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.mockup-frame251 {
  gap: 24px;
  width: 748px;
  display: flex;
  padding: 24px;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.029999999329447746) ;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(20, 20, 20, 0.05000000074505806);
  border-style: solid;
  border-width: 1px;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 1);
}
.mockup-frame173 {
  width: 72px;
  height: 72px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: 4px;
  background-size: cover;
  background-image: url("/external/cba22aab-1c48-43e8-a9b2-e3c784bef35a-1uij-200h.png");
}
.mockup-frame233 {
  gap: 12px;
  width: 604px;
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.mockup-frame283 {
  gap: 6px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.mockup-frame293 {
  gap: 4px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.mockup-frame274 {
  gap: 12px;
  display: flex;
  align-items: center;
}
.mockup-text100 {
  color: rgba(20, 20, 20, 1);
  height: auto;
  font-size: 18px;
  font-style: Regular;
  text-align: left;
  font-family: DM Sans;
  font-weight: 400;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.mockup-frame3006 {
  gap: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.mockup-text102 {
  color: rgba(20, 20, 20, 1);
  height: auto;
  font-size: 24px;
  font-style: Medium;
  text-align: left;
  font-family: DM Sans;
  font-weight: 500;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.mockup-frame3007 {
  gap: 24px;
  display: flex;
  padding: 2px 0;
  align-items: center;
  justify-content: center;
}
.mockup-frame183 {
  gap: 6px;
  display: flex;
  align-items: center;
}
.mockup-map-pin-line3 {
  width: 16px;
  height: 16px;
}
.mockup-text104 {
  color: rgba(20, 20, 20, 0.699999988079071);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: DM Sans;
  font-weight: 400;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.mockup-ellipse33 {
  width: 2px;
  height: 2px;
}
.mockup-frame203 {
  gap: 6px;
  display: flex;
  align-items: center;
}
.mockup-text106 {
  color: rgba(20, 20, 20, 0.699999988079071);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: DM Sans;
  font-weight: 400;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.mockup-ellipse23 {
  width: 2px;
  height: 2px;
}
.mockup-frame213 {
  gap: 6px;
  display: flex;
  align-items: center;
}
.mockup-currency-dollar3 {
  width: 16px;
  height: 16px;
}
.mockup-text108 {
  color: rgba(20, 20, 20, 0.699999988079071);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: DM Sans;
  font-weight: 400;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.mockup-ellipse13 {
  width: 2px;
  height: 2px;
}
.mockup-frame223 {
  gap: 6px;
  display: flex;
  align-items: center;
}
.mockup-clock3 {
  width: 16px;
  height: 16px;
}
.mockup-text110 {
  color: rgba(20, 20, 20, 0.699999988079071);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: DM Sans;
  font-weight: 400;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.mockup-frame47 {
  gap: 24px;
  width: 748px;
  display: flex;
  padding: 24px;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.029999999329447746) ;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(20, 20, 20, 0.05000000074505806);
  border-style: solid;
  border-width: 1px;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 1);
}
.mockup-frame174 {
  width: 72px;
  height: 72px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: 4px;
  background-size: cover;
  background-image: url("/external/2f15275e-4d9e-412f-aa40-e9aea66e68d3-nltq-200h.png");
}
.mockup-frame234 {
  gap: 12px;
  width: 604px;
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.mockup-frame284 {
  gap: 6px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.mockup-frame294 {
  gap: 4px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.mockup-frame275 {
  gap: 12px;
  display: flex;
  align-items: center;
}
.mockup-text111 {
  color: rgba(20, 20, 20, 1);
  height: auto;
  font-size: 18px;
  font-style: Regular;
  text-align: left;
  font-family: DM Sans;
  font-weight: 400;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.mockup-frame3008 {
  gap: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.mockup-text113 {
  color: rgba(20, 20, 20, 1);
  height: auto;
  font-size: 24px;
  font-style: Medium;
  text-align: left;
  font-family: DM Sans;
  font-weight: 500;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.mockup-frame3009 {
  gap: 24px;
  display: flex;
  padding: 2px 0;
  align-items: center;
  justify-content: center;
}
.mockup-frame184 {
  gap: 6px;
  display: flex;
  align-items: center;
}
.mockup-map-pin-line4 {
  width: 16px;
  height: 16px;
}
.mockup-text115 {
  color: rgba(20, 20, 20, 0.699999988079071);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: DM Sans;
  font-weight: 400;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.mockup-ellipse34 {
  width: 2px;
  height: 2px;
}
.mockup-frame204 {
  gap: 6px;
  display: flex;
  align-items: center;
}
.mockup-text117 {
  color: rgba(20, 20, 20, 0.699999988079071);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: DM Sans;
  font-weight: 400;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.mockup-ellipse24 {
  width: 2px;
  height: 2px;
}
.mockup-frame214 {
  gap: 6px;
  display: flex;
  align-items: center;
}
.mockup-currency-dollar4 {
  width: 16px;
  height: 16px;
}
.mockup-text119 {
  color: rgba(20, 20, 20, 0.699999988079071);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: DM Sans;
  font-weight: 400;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.mockup-ellipse14 {
  width: 2px;
  height: 2px;
}
.mockup-frame224 {
  gap: 6px;
  display: flex;
  align-items: center;
}
.mockup-clock4 {
  width: 16px;
  height: 16px;
}
.mockup-text121 {
  color: rgba(20, 20, 20, 0.699999988079071);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: DM Sans;
  font-weight: 400;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.mockup-frame24 {
  gap: 24px;
  width: 748px;
  display: flex;
  padding: 24px;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.029999999329447746) ;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(20, 20, 20, 0.05000000074505806);
  border-style: solid;
  border-width: 1px;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 1);
}
.mockup-frame175 {
  width: 72px;
  height: 72px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: 4px;
  background-size: cover;
  background-image: url("/external/d1e63373-7a47-4023-bc19-ccac033f48b7-j76-200h.png");
}
.mockup-frame235 {
  gap: 12px;
  width: 604px;
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.mockup-frame285 {
  gap: 6px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.mockup-frame295 {
  gap: 4px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.mockup-frame276 {
  gap: 12px;
  display: flex;
  align-items: center;
}
.mockup-text122 {
  color: rgba(20, 20, 20, 1);
  height: auto;
  font-size: 18px;
  font-style: Regular;
  text-align: left;
  font-family: DM Sans;
  font-weight: 400;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.mockup-frame3010 {
  gap: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.mockup-text124 {
  color: rgba(20, 20, 20, 1);
  height: auto;
  font-size: 24px;
  font-style: Medium;
  text-align: left;
  font-family: DM Sans;
  font-weight: 500;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.mockup-frame225 {
  gap: 24px;
  display: flex;
  padding: 2px 0;
  align-items: center;
  justify-content: center;
}
.mockup-frame185 {
  gap: 6px;
  display: flex;
  align-items: center;
}
.mockup-map-pin-line5 {
  width: 16px;
  height: 16px;
}
.mockup-text126 {
  color: rgba(20, 20, 20, 0.699999988079071);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: DM Sans;
  font-weight: 400;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.mockup-ellipse35 {
  width: 2px;
  height: 2px;
}
.mockup-frame205 {
  gap: 6px;
  display: flex;
  align-items: center;
}
.mockup-clock5 {
  width: 16px;
  height: 16px;
}
.mockup-text128 {
  color: rgba(20, 20, 20, 0.699999988079071);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: DM Sans;
  font-weight: 400;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.mockup-ellipse25 {
  width: 2px;
  height: 2px;
}
.mockup-frame215 {
  gap: 6px;
  display: flex;
  align-items: center;
}
.mockup-currency-dollar5 {
  width: 16px;
  height: 16px;
}
.mockup-text130 {
  color: rgba(20, 20, 20, 0.699999988079071);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: DM Sans;
  font-weight: 400;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.mockup-ellipse15 {
  width: 2px;
  height: 2px;
}
.mockup-frame226 {
  gap: 6px;
  display: flex;
  align-items: center;
}
.mockup-clock6 {
  width: 16px;
  height: 16px;
}
.mockup-text132 {
  color: rgba(20, 20, 20, 0.699999988079071);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: DM Sans;
  font-weight: 400;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.mockup-pagination {
  gap: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.mockup-button {
  gap: 4px;
  width: 40px;
  height: 40px;
  display: flex;
  padding: 8px 16px;
  align-items: center;
  flex-shrink: 0;
  border-color: rgba(17, 17, 17, 0.10000000149011612);
  border-style: solid;
  border-width: 1px;
  border-radius: 2px;
  justify-content: center;
  background-color: rgba(255, 255, 255, 1);
}
.mockup-chevron-down {
  width: 16px;
  height: 16px;
}
.mockup-button01 {
  gap: 4px;
  width: 40px;
  height: 40px;
  display: flex;
  padding: 8px 16px;
  align-items: center;
  flex-shrink: 0;
  border-radius: 2px;
  justify-content: center;
  background-color: rgba(53, 117, 226, 1);
}
.mockup-text133 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  font-size: 16px;
  font-style: Bold;
  text-align: left;
  font-family: DM Sans;
  font-weight: 700;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.mockup-button02 {
  gap: 4px;
  width: 40px;
  height: 40px;
  display: flex;
  padding: 8px 16px;
  align-items: center;
  flex-shrink: 0;
  border-color: rgba(17, 17, 17, 0.10000000149011612);
  border-style: solid;
  border-width: 1px;
  border-radius: 2px;
  justify-content: center;
  background-color: rgba(255, 255, 255, 1);
}
.mockup-text134 {
  color: rgba(17, 17, 17, 1);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: DM Sans;
  font-weight: 400;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.mockup-button03 {
  gap: 4px;
  width: 40px;
  height: 40px;
  display: flex;
  padding: 8px 16px;
  align-items: center;
  flex-shrink: 0;
  border-color: rgba(17, 17, 17, 0.10000000149011612);
  border-style: solid;
  border-width: 1px;
  border-radius: 2px;
  justify-content: center;
  background-color: rgba(255, 255, 255, 1);
}
.mockup-text135 {
  color: rgba(17, 17, 17, 1);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: DM Sans;
  font-weight: 400;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.mockup-button04 {
  gap: 4px;
  width: 40px;
  height: 40px;
  display: flex;
  padding: 8px 16px;
  align-items: center;
  flex-shrink: 0;
  border-color: rgba(17, 17, 17, 0.10000000149011612);
  border-style: solid;
  border-width: 1px;
  border-radius: 2px;
  justify-content: center;
  background-color: rgba(255, 255, 255, 1);
}
.mockup-text136 {
  color: rgba(17, 17, 17, 1);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: DM Sans;
  font-weight: 400;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.mockup-button05 {
  gap: 4px;
  width: 40px;
  height: 40px;
  display: flex;
  padding: 8px 16px;
  align-items: center;
  flex-shrink: 0;
  border-color: rgba(17, 17, 17, 0.10000000149011612);
  border-style: solid;
  border-width: 1px;
  border-radius: 2px;
  justify-content: center;
  background-color: rgba(255, 255, 255, 1);
}
.mockup-text137 {
  color: rgba(17, 17, 17, 1);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: DM Sans;
  font-weight: 400;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.mockup-button06 {
  gap: 4px;
  width: 40px;
  height: 40px;
  display: flex;
  padding: 8px 16px;
  align-items: center;
  flex-shrink: 0;
  border-color: rgba(17, 17, 17, 0.10000000149011612);
  border-style: solid;
  border-width: 1px;
  border-radius: 2px;
  justify-content: center;
  background-color: rgba(255, 255, 255, 1);
}
.mockup-chevron-down1 {
  width: 16px;
  height: 16px;
}
.mockup-moreoption {
  gap: 16px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.mockup-frame16 {
  gap: 16px;
  width: 250px;
  display: flex;
  padding: 24px;
  overflow: hidden;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.029999999329447746) ;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(20, 20, 20, 0.05000000074505806);
  border-style: solid;
  border-width: 1px;
  border-radius: 8px;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 1);
}
.mockup-frame363 {
  gap: 16px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.mockup-text138 {
  color: rgba(20, 20, 20, 1);
  height: auto;
  font-size: 20px;
  align-self: stretch;
  font-style: Bold;
  text-align: left;
  font-family: DM Sans;
  font-weight: 700;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.mockup-frame371 {
  gap: 16px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.mockup-text140 {
  color: rgba(20, 20, 20, 0.800000011920929);
  height: auto;
  font-size: 14px;
  align-self: stretch;
  font-style: Regular;
  text-align: left;
  font-family: DM Sans;
  font-weight: 400;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.mockup-frame72 {
  gap: 12px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.mockup-frame141 {
  gap: 16px;
  width: 202px;
  display: flex;
  padding: 8px 12px;
  overflow: hidden;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.029999999329447746) ;
  align-items: center;
  flex-shrink: 0;
  border-color: rgba(20, 20, 20, 0.20000000298023224);
  border-style: solid;
  border-width: 1px;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 1);
}
.mockup-text142 {
  color: rgba(20, 20, 20, 0.5);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: DM Sans;
  font-weight: 400;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.mockup-button07 {
  gap: 8px;
  width: 202px;
  display: flex;
  padding: 8px 20px 8px 16px;
  box-sizing: content-box;
  align-items: center;
  flex-shrink: 0;
  border-radius: 4px;
  justify-content: center;
  background-color: rgba(53, 117, 226, 1);
}
.mockup-text144 {
  color: var(--dl-color-default-white);
  height: auto;
  font-size: 16px;
  font-style: Medium;
  text-align: left;
  font-family: DM Sans;
  font-weight: 500;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.mockup-frame186 {
  gap: 16px;
  width: 250px;
  display: flex;
  padding: 24px;
  overflow: hidden;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.029999999329447746) ;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(20, 20, 20, 0.05000000074505806);
  border-style: solid;
  border-width: 1px;
  border-radius: 8px;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 1);
}
.mockup-frame364 {
  gap: 16px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.mockup-text146 {
  color: rgba(20, 20, 20, 1);
  height: auto;
  font-size: 20px;
  font-style: Bold;
  text-align: left;
  font-family: DM Sans;
  font-weight: 700;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.mockup-frame372 {
  gap: 16px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.mockup-text148 {
  color: rgba(20, 20, 20, 0.800000011920929);
  height: auto;
  font-size: 14px;
  align-self: stretch;
  font-style: Regular;
  text-align: left;
  font-family: DM Sans;
  font-weight: 400;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.mockup-button08 {
  gap: 8px;
  display: flex;
  padding: 8px 20px 8px 16px;
  align-self: stretch;
  box-sizing: content-box;
  align-items: center;
  flex-shrink: 0;
  border-radius: 4px;
  justify-content: center;
  background-color: rgba(53, 117, 226, 1);
}
.mockup-text150 {
  color: var(--dl-color-default-white);
  height: auto;
  font-size: 16px;
  font-style: Medium;
  text-align: left;
  font-family: DM Sans;
  font-weight: 500;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.mockup-search {
  gap: 8px;
  top: 72px;
  left: 0px;
  width: 1440px;
  display: flex;
  padding: 80px 64px;
  overflow: hidden;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: center;
}
.mockup-group2 {
  top: -182px;
  left: 222px;
  width: 2097px;
  height: 1138.01611328125px;
  display: flex;
  opacity: 0.80;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
.mockup-group {
  top: 0.15771484375px;
  left: 125.9998779296875px;
  width: 1971px;
  height: 1137.8583984375px;
  display: flex;
  opacity: 0.50;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
.mockup-frame2 {
  top: 0px;
  left: 798.0642700195312px;
  width: 1354.3599853515625px;
  height: 921.5051879882812px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.mockup-frame60 {
  display: flex;
  align-items: flex-start;
}
.mockup-frame4801 {
  width: 100px;
  height: 100px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(20, 20, 20, 0.10000000149011612);
  border-style: solid;
  border-width: 1px;
}
.mockup-frame49 {
  width: 100px;
  height: 100px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(20, 20, 20, 0.10000000149011612);
  border-style: solid;
  border-width: 1px;
}
.mockup-frame50 {
  width: 100px;
  height: 100px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(20, 20, 20, 0.10000000149011612);
  border-style: solid;
  border-width: 1px;
}
.mockup-frame51 {
  width: 100px;
  height: 100px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(20, 20, 20, 0.10000000149011612);
  border-style: solid;
  border-width: 1px;
}
.mockup-frame52 {
  width: 100px;
  height: 100px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(20, 20, 20, 0.10000000149011612);
  border-style: solid;
  border-width: 1px;
}
.mockup-frame53 {
  width: 100px;
  height: 100px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(20, 20, 20, 0.10000000149011612);
  border-style: solid;
  border-width: 1px;
}
.mockup-frame54 {
  width: 100px;
  height: 100px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(20, 20, 20, 0.10000000149011612);
  border-style: solid;
  border-width: 1px;
}
.mockup-frame55 {
  width: 100px;
  height: 100px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(20, 20, 20, 0.10000000149011612);
  border-style: solid;
  border-width: 1px;
}
.mockup-frame56 {
  width: 100px;
  height: 100px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(20, 20, 20, 0.10000000149011612);
  border-style: solid;
  border-width: 1px;
}
.mockup-frame57 {
  width: 100px;
  height: 100px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(20, 20, 20, 0.10000000149011612);
  border-style: solid;
  border-width: 1px;
}
.mockup-frame58 {
  width: 100px;
  height: 100px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(20, 20, 20, 0.10000000149011612);
  border-style: solid;
  border-width: 1px;
}
.mockup-frame59 {
  width: 100px;
  height: 100px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(20, 20, 20, 0.10000000149011612);
  border-style: solid;
  border-width: 1px;
}
.mockup-frame61 {
  display: flex;
  align-items: flex-start;
}
.mockup-frame4802 {
  width: 100px;
  height: 100px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(20, 20, 20, 0.10000000149011612);
  border-style: solid;
  border-width: 1px;
}
.mockup-frame4901 {
  width: 100px;
  height: 100px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(20, 20, 20, 0.10000000149011612);
  border-style: solid;
  border-width: 1px;
}
.mockup-frame5001 {
  width: 100px;
  height: 100px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(20, 20, 20, 0.10000000149011612);
  border-style: solid;
  border-width: 1px;
}
.mockup-frame5101 {
  width: 100px;
  height: 100px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(20, 20, 20, 0.10000000149011612);
  border-style: solid;
  border-width: 1px;
}
.mockup-frame5201 {
  width: 100px;
  height: 100px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(20, 20, 20, 0.10000000149011612);
  border-style: solid;
  border-width: 1px;
}
.mockup-frame5301 {
  width: 100px;
  height: 100px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(20, 20, 20, 0.10000000149011612);
  border-style: solid;
  border-width: 1px;
}
.mockup-frame5401 {
  width: 100px;
  height: 100px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(20, 20, 20, 0.10000000149011612);
  border-style: solid;
  border-width: 1px;
}
.mockup-frame5501 {
  width: 100px;
  height: 100px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(20, 20, 20, 0.10000000149011612);
  border-style: solid;
  border-width: 1px;
}
.mockup-frame5601 {
  width: 100px;
  height: 100px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(20, 20, 20, 0.10000000149011612);
  border-style: solid;
  border-width: 1px;
}
.mockup-frame5701 {
  width: 100px;
  height: 100px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(20, 20, 20, 0.10000000149011612);
  border-style: solid;
  border-width: 1px;
}
.mockup-frame5801 {
  width: 100px;
  height: 100px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(20, 20, 20, 0.10000000149011612);
  border-style: solid;
  border-width: 1px;
}
.mockup-frame5901 {
  width: 100px;
  height: 100px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(20, 20, 20, 0.10000000149011612);
  border-style: solid;
  border-width: 1px;
}
.mockup-frame62 {
  display: flex;
  align-items: flex-start;
}
.mockup-frame4803 {
  width: 100px;
  height: 100px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(20, 20, 20, 0.10000000149011612);
  border-style: solid;
  border-width: 1px;
}
.mockup-frame4902 {
  width: 100px;
  height: 100px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(20, 20, 20, 0.10000000149011612);
  border-style: solid;
  border-width: 1px;
}
.mockup-frame5002 {
  width: 100px;
  height: 100px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(20, 20, 20, 0.10000000149011612);
  border-style: solid;
  border-width: 1px;
}
.mockup-frame5102 {
  width: 100px;
  height: 100px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(20, 20, 20, 0.10000000149011612);
  border-style: solid;
  border-width: 1px;
}
.mockup-frame5202 {
  width: 100px;
  height: 100px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(20, 20, 20, 0.10000000149011612);
  border-style: solid;
  border-width: 1px;
}
.mockup-frame5302 {
  width: 100px;
  height: 100px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(20, 20, 20, 0.10000000149011612);
  border-style: solid;
  border-width: 1px;
}
.mockup-frame5402 {
  width: 100px;
  height: 100px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(20, 20, 20, 0.10000000149011612);
  border-style: solid;
  border-width: 1px;
}
.mockup-frame5502 {
  width: 100px;
  height: 100px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(20, 20, 20, 0.10000000149011612);
  border-style: solid;
  border-width: 1px;
}
.mockup-frame5602 {
  width: 100px;
  height: 100px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(20, 20, 20, 0.10000000149011612);
  border-style: solid;
  border-width: 1px;
}
.mockup-frame5702 {
  width: 100px;
  height: 100px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(20, 20, 20, 0.10000000149011612);
  border-style: solid;
  border-width: 1px;
}
.mockup-frame5802 {
  width: 100px;
  height: 100px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(20, 20, 20, 0.10000000149011612);
  border-style: solid;
  border-width: 1px;
}
.mockup-frame5902 {
  width: 100px;
  height: 100px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(20, 20, 20, 0.10000000149011612);
  border-style: solid;
  border-width: 1px;
}
.mockup-frame63 {
  display: flex;
  align-items: flex-start;
}
.mockup-frame4804 {
  width: 100px;
  height: 100px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(20, 20, 20, 0.10000000149011612);
  border-style: solid;
  border-width: 1px;
}
.mockup-frame4903 {
  width: 100px;
  height: 100px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(20, 20, 20, 0.10000000149011612);
  border-style: solid;
  border-width: 1px;
}
.mockup-frame5003 {
  width: 100px;
  height: 100px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(20, 20, 20, 0.10000000149011612);
  border-style: solid;
  border-width: 1px;
}
.mockup-frame5103 {
  width: 100px;
  height: 100px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(20, 20, 20, 0.10000000149011612);
  border-style: solid;
  border-width: 1px;
}
.mockup-frame5203 {
  width: 100px;
  height: 100px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(20, 20, 20, 0.10000000149011612);
  border-style: solid;
  border-width: 1px;
}
.mockup-frame5303 {
  width: 100px;
  height: 100px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(20, 20, 20, 0.10000000149011612);
  border-style: solid;
  border-width: 1px;
}
.mockup-frame5403 {
  width: 100px;
  height: 100px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(20, 20, 20, 0.10000000149011612);
  border-style: solid;
  border-width: 1px;
}
.mockup-frame5503 {
  width: 100px;
  height: 100px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(20, 20, 20, 0.10000000149011612);
  border-style: solid;
  border-width: 1px;
}
.mockup-frame5603 {
  width: 100px;
  height: 100px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(20, 20, 20, 0.10000000149011612);
  border-style: solid;
  border-width: 1px;
}
.mockup-frame5703 {
  width: 100px;
  height: 100px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(20, 20, 20, 0.10000000149011612);
  border-style: solid;
  border-width: 1px;
}
.mockup-frame5803 {
  width: 100px;
  height: 100px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(20, 20, 20, 0.10000000149011612);
  border-style: solid;
  border-width: 1px;
}
.mockup-frame5903 {
  width: 100px;
  height: 100px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(20, 20, 20, 0.10000000149011612);
  border-style: solid;
  border-width: 1px;
}
.mockup-frame64 {
  display: flex;
  align-items: flex-start;
}
.mockup-frame4805 {
  width: 100px;
  height: 100px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(20, 20, 20, 0.10000000149011612);
  border-style: solid;
  border-width: 1px;
}
.mockup-frame4904 {
  width: 100px;
  height: 100px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(20, 20, 20, 0.10000000149011612);
  border-style: solid;
  border-width: 1px;
}
.mockup-frame5004 {
  width: 100px;
  height: 100px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(20, 20, 20, 0.10000000149011612);
  border-style: solid;
  border-width: 1px;
}
.mockup-frame5104 {
  width: 100px;
  height: 100px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(20, 20, 20, 0.10000000149011612);
  border-style: solid;
  border-width: 1px;
}
.mockup-frame5204 {
  width: 100px;
  height: 100px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(20, 20, 20, 0.10000000149011612);
  border-style: solid;
  border-width: 1px;
}
.mockup-frame5304 {
  width: 100px;
  height: 100px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(20, 20, 20, 0.10000000149011612);
  border-style: solid;
  border-width: 1px;
}
.mockup-frame5404 {
  width: 100px;
  height: 100px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(20, 20, 20, 0.10000000149011612);
  border-style: solid;
  border-width: 1px;
}
.mockup-frame5504 {
  width: 100px;
  height: 100px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(20, 20, 20, 0.10000000149011612);
  border-style: solid;
  border-width: 1px;
}
.mockup-frame5604 {
  width: 100px;
  height: 100px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(20, 20, 20, 0.10000000149011612);
  border-style: solid;
  border-width: 1px;
}
.mockup-frame5704 {
  width: 100px;
  height: 100px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(20, 20, 20, 0.10000000149011612);
  border-style: solid;
  border-width: 1px;
}
.mockup-frame5804 {
  width: 100px;
  height: 100px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(20, 20, 20, 0.10000000149011612);
  border-style: solid;
  border-width: 1px;
}
.mockup-frame5904 {
  width: 100px;
  height: 100px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(20, 20, 20, 0.10000000149011612);
  border-style: solid;
  border-width: 1px;
}
.mockup-frame65 {
  display: flex;
  align-items: flex-start;
}
.mockup-frame4806 {
  width: 100px;
  height: 100px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(20, 20, 20, 0.10000000149011612);
  border-style: solid;
  border-width: 1px;
}
.mockup-frame4905 {
  width: 100px;
  height: 100px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(20, 20, 20, 0.10000000149011612);
  border-style: solid;
  border-width: 1px;
}
.mockup-frame5005 {
  width: 100px;
  height: 100px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(20, 20, 20, 0.10000000149011612);
  border-style: solid;
  border-width: 1px;
}
.mockup-frame5105 {
  width: 100px;
  height: 100px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(20, 20, 20, 0.10000000149011612);
  border-style: solid;
  border-width: 1px;
}
.mockup-frame5205 {
  width: 100px;
  height: 100px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(20, 20, 20, 0.10000000149011612);
  border-style: solid;
  border-width: 1px;
}
.mockup-frame5305 {
  width: 100px;
  height: 100px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(20, 20, 20, 0.10000000149011612);
  border-style: solid;
  border-width: 1px;
}
.mockup-frame5405 {
  width: 100px;
  height: 100px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(20, 20, 20, 0.10000000149011612);
  border-style: solid;
  border-width: 1px;
}
.mockup-frame5505 {
  width: 100px;
  height: 100px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(20, 20, 20, 0.10000000149011612);
  border-style: solid;
  border-width: 1px;
}
.mockup-frame5605 {
  width: 100px;
  height: 100px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(20, 20, 20, 0.10000000149011612);
  border-style: solid;
  border-width: 1px;
}
.mockup-frame5705 {
  width: 100px;
  height: 100px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(20, 20, 20, 0.10000000149011612);
  border-style: solid;
  border-width: 1px;
}
.mockup-frame5805 {
  width: 100px;
  height: 100px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(20, 20, 20, 0.10000000149011612);
  border-style: solid;
  border-width: 1px;
}
.mockup-frame5905 {
  width: 100px;
  height: 100px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(20, 20, 20, 0.10000000149011612);
  border-style: solid;
  border-width: 1px;
}
.mockup-frame66 {
  display: flex;
  align-items: flex-start;
}
.mockup-frame4807 {
  width: 100px;
  height: 100px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(20, 20, 20, 0.10000000149011612);
  border-style: solid;
  border-width: 1px;
}
.mockup-frame4906 {
  width: 100px;
  height: 100px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(20, 20, 20, 0.10000000149011612);
  border-style: solid;
  border-width: 1px;
}
.mockup-frame5006 {
  width: 100px;
  height: 100px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(20, 20, 20, 0.10000000149011612);
  border-style: solid;
  border-width: 1px;
}
.mockup-frame5106 {
  width: 100px;
  height: 100px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(20, 20, 20, 0.10000000149011612);
  border-style: solid;
  border-width: 1px;
}
.mockup-frame5206 {
  width: 100px;
  height: 100px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(20, 20, 20, 0.10000000149011612);
  border-style: solid;
  border-width: 1px;
}
.mockup-frame5306 {
  width: 100px;
  height: 100px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(20, 20, 20, 0.10000000149011612);
  border-style: solid;
  border-width: 1px;
}
.mockup-frame5406 {
  width: 100px;
  height: 100px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(20, 20, 20, 0.10000000149011612);
  border-style: solid;
  border-width: 1px;
}
.mockup-frame5506 {
  width: 100px;
  height: 100px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(20, 20, 20, 0.10000000149011612);
  border-style: solid;
  border-width: 1px;
}
.mockup-frame5606 {
  width: 100px;
  height: 100px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(20, 20, 20, 0.10000000149011612);
  border-style: solid;
  border-width: 1px;
}
.mockup-frame5706 {
  width: 100px;
  height: 100px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(20, 20, 20, 0.10000000149011612);
  border-style: solid;
  border-width: 1px;
}
.mockup-frame5806 {
  width: 100px;
  height: 100px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(20, 20, 20, 0.10000000149011612);
  border-style: solid;
  border-width: 1px;
}
.mockup-frame5906 {
  width: 100px;
  height: 100px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(20, 20, 20, 0.10000000149011612);
  border-style: solid;
  border-width: 1px;
}
.mockup-frame67 {
  display: flex;
  align-items: flex-start;
}
.mockup-frame4808 {
  width: 100px;
  height: 100px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(20, 20, 20, 0.10000000149011612);
  border-style: solid;
  border-width: 1px;
}
.mockup-frame4907 {
  width: 100px;
  height: 100px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(20, 20, 20, 0.10000000149011612);
  border-style: solid;
  border-width: 1px;
}
.mockup-frame5007 {
  width: 100px;
  height: 100px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(20, 20, 20, 0.10000000149011612);
  border-style: solid;
  border-width: 1px;
}
.mockup-frame5107 {
  width: 100px;
  height: 100px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(20, 20, 20, 0.10000000149011612);
  border-style: solid;
  border-width: 1px;
}
.mockup-frame5207 {
  width: 100px;
  height: 100px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(20, 20, 20, 0.10000000149011612);
  border-style: solid;
  border-width: 1px;
}
.mockup-frame5307 {
  width: 100px;
  height: 100px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(20, 20, 20, 0.10000000149011612);
  border-style: solid;
  border-width: 1px;
}
.mockup-frame5407 {
  width: 100px;
  height: 100px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(20, 20, 20, 0.10000000149011612);
  border-style: solid;
  border-width: 1px;
}
.mockup-frame5507 {
  width: 100px;
  height: 100px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(20, 20, 20, 0.10000000149011612);
  border-style: solid;
  border-width: 1px;
}
.mockup-frame5607 {
  width: 100px;
  height: 100px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(20, 20, 20, 0.10000000149011612);
  border-style: solid;
  border-width: 1px;
}
.mockup-frame5707 {
  width: 100px;
  height: 100px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(20, 20, 20, 0.10000000149011612);
  border-style: solid;
  border-width: 1px;
}
.mockup-frame5807 {
  width: 100px;
  height: 100px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(20, 20, 20, 0.10000000149011612);
  border-style: solid;
  border-width: 1px;
}
.mockup-frame5907 {
  width: 100px;
  height: 100px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(20, 20, 20, 0.10000000149011612);
  border-style: solid;
  border-width: 1px;
}
.mockup-frame68 {
  display: flex;
  align-items: flex-start;
}
.mockup-frame4809 {
  width: 100px;
  height: 100px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(20, 20, 20, 0.10000000149011612);
  border-style: solid;
  border-width: 1px;
}
.mockup-frame4908 {
  width: 100px;
  height: 100px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(20, 20, 20, 0.10000000149011612);
  border-style: solid;
  border-width: 1px;
}
.mockup-frame5008 {
  width: 100px;
  height: 100px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(20, 20, 20, 0.10000000149011612);
  border-style: solid;
  border-width: 1px;
}
.mockup-frame5108 {
  width: 100px;
  height: 100px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(20, 20, 20, 0.10000000149011612);
  border-style: solid;
  border-width: 1px;
}
.mockup-frame5208 {
  width: 100px;
  height: 100px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(20, 20, 20, 0.10000000149011612);
  border-style: solid;
  border-width: 1px;
}
.mockup-frame5308 {
  width: 100px;
  height: 100px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(20, 20, 20, 0.10000000149011612);
  border-style: solid;
  border-width: 1px;
}
.mockup-frame5408 {
  width: 100px;
  height: 100px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(20, 20, 20, 0.10000000149011612);
  border-style: solid;
  border-width: 1px;
}
.mockup-frame5508 {
  width: 100px;
  height: 100px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(20, 20, 20, 0.10000000149011612);
  border-style: solid;
  border-width: 1px;
}
.mockup-frame5608 {
  width: 100px;
  height: 100px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(20, 20, 20, 0.10000000149011612);
  border-style: solid;
  border-width: 1px;
}
.mockup-frame5708 {
  width: 100px;
  height: 100px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(20, 20, 20, 0.10000000149011612);
  border-style: solid;
  border-width: 1px;
}
.mockup-frame5808 {
  width: 100px;
  height: 100px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(20, 20, 20, 0.10000000149011612);
  border-style: solid;
  border-width: 1px;
}
.mockup-frame5908 {
  width: 100px;
  height: 100px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(20, 20, 20, 0.10000000149011612);
  border-style: solid;
  border-width: 1px;
}
.mockup-frame69 {
  display: flex;
  align-items: flex-start;
}
.mockup-frame4810 {
  width: 100px;
  height: 100px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(20, 20, 20, 0.10000000149011612);
  border-style: solid;
  border-width: 1px;
}
.mockup-frame4909 {
  width: 100px;
  height: 100px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(20, 20, 20, 0.10000000149011612);
  border-style: solid;
  border-width: 1px;
}
.mockup-frame5009 {
  width: 100px;
  height: 100px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(20, 20, 20, 0.10000000149011612);
  border-style: solid;
  border-width: 1px;
}
.mockup-frame5109 {
  width: 100px;
  height: 100px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(20, 20, 20, 0.10000000149011612);
  border-style: solid;
  border-width: 1px;
}
.mockup-frame5209 {
  width: 100px;
  height: 100px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(20, 20, 20, 0.10000000149011612);
  border-style: solid;
  border-width: 1px;
}
.mockup-frame5309 {
  width: 100px;
  height: 100px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(20, 20, 20, 0.10000000149011612);
  border-style: solid;
  border-width: 1px;
}
.mockup-frame5409 {
  width: 100px;
  height: 100px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(20, 20, 20, 0.10000000149011612);
  border-style: solid;
  border-width: 1px;
}
.mockup-frame5509 {
  width: 100px;
  height: 100px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(20, 20, 20, 0.10000000149011612);
  border-style: solid;
  border-width: 1px;
}
.mockup-frame5609 {
  width: 100px;
  height: 100px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(20, 20, 20, 0.10000000149011612);
  border-style: solid;
  border-width: 1px;
}
.mockup-frame5709 {
  width: 100px;
  height: 100px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(20, 20, 20, 0.10000000149011612);
  border-style: solid;
  border-width: 1px;
}
.mockup-frame5809 {
  width: 100px;
  height: 100px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(20, 20, 20, 0.10000000149011612);
  border-style: solid;
  border-width: 1px;
}
.mockup-frame5909 {
  width: 100px;
  height: 100px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(20, 20, 20, 0.10000000149011612);
  border-style: solid;
  border-width: 1px;
}
.mockup-rectangle1 {
  top: 0px;
  left: 0px;
  width: 1011px;
  height: 1138px;
  position: absolute;
}
.mockup-frame12 {
  gap: 40px;
  display: flex;
  z-index: 1;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.mockup-frame11 {
  gap: 8px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.mockup-text152 {
  color: rgba(20, 20, 20, 1);
  height: auto;
  font-size: 56px;
  font-style: Bold;
  text-align: left;
  font-family: DM Sans;
  font-weight: 700;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.mockup-text153 {
  color: rgba(20, 20, 20, 1);
}
.mockup-text154 {
  color: rgba(53, 117, 226, 1);
}
.mockup-text156 {
  color: rgba(0, 0, 0, 0.699999988079071);
  height: auto;
  font-size: 18px;
  font-style: Regular;
  text-align: left;
  font-family: DM Sans;
  font-weight: 400;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.mockup-frame10 {
  gap: -1px;
  display: flex;
  align-self: stretch;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.03999999910593033) ;
  align-items: flex-start;
  flex-shrink: 0;
}
.mockup-frame9 {
  gap: 16px;
  display: flex;
  padding: 18px;
  overflow: hidden;
  flex-grow: 1;
  align-items: center;
  border-color: rgba(20, 20, 20, 0.10000000149011612);
  border-style: solid;
  border-width: 1px;
  border-radius: 4px 0 0 4px;
  background-color: rgba(255, 255, 255, 1);
}
.mockup-magnifying-glass {
  width: 24px;
  height: 24px;
}
.mockup-text158 {
  color: rgba(20, 20, 20, 0.5);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: DM Sans;
  font-weight: 400;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.mockup-frame142 {
  gap: 16px;
  width: 420px;
  display: flex;
  padding: 18px;
  overflow: hidden;
  align-items: center;
  flex-shrink: 0;
  border-color: rgba(20, 20, 20, 0.10000000149011612);
  border-style: solid;
  border-width: 1px;
  background-color: rgba(255, 255, 255, 1);
}
.mockup-map-pin-line6 {
  width: 24px;
  height: 24px;
}
.mockup-text160 {
  color: rgba(20, 20, 20, 0.5);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: DM Sans;
  font-weight: 400;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.mockup-button09 {
  gap: 8px;
  display: flex;
  padding: 18px 40px;
  box-sizing: content-box;
  align-items: center;
  border-radius: 0 4px 4px 0;
  justify-content: center;
  background-color: rgba(53, 117, 226, 1);
}
.mockup-text162 {
  color: var(--dl-color-default-white);
  height: auto;
  font-size: 16px;
  font-style: Medium;
  text-align: left;
  font-family: DM Sans;
  font-weight: 500;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.mockup-navbar {
  top: 0px;
  left: 0px;
  width: 1440px;
  height: 72px;
  display: flex;
  padding: 0 64px;
  position: absolute;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-default-white);
}
.mockup-container2 {
  gap: 32px;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  justify-content: space-between;
}
.mockup-frame471 {
  gap: 12px;
  display: flex;
  align-items: center;
}
.mockup-group3 {
  width: 29px;
  height: 29px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 1;
}
.mockup-ellipse8 {
  top: 0px;
  left: 0px;
  width: 24px;
  height: 24px;
  position: absolute;
}
.mockup-ellipse9 {
  top: 4.971428394317627px;
  left: 4.971428394317627px;
  width: 24px;
  height: 24px;
  position: absolute;
}
.mockup-text164 {
  color: rgba(20, 20, 20, 1);
  height: auto;
  font-size: 24px;
  font-style: Regular;
  text-align: left;
  font-family: DM Sans;
  font-weight: 400;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.mockup-column {
  gap: 48px;
  display: flex;
  overflow: hidden;
  align-items: flex-start;
}
.mockup-text165 {
  color: rgba(20, 20, 20, 1);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: DM Sans;
  font-weight: 400;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.mockup-text166 {
  color: rgba(20, 20, 20, 1);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: DM Sans;
  font-weight: 400;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.mockup-text167 {
  color: rgba(20, 20, 20, 1);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: DM Sans;
  font-weight: 400;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.mockup-text168 {
  color: rgba(20, 20, 20, 1);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: DM Sans;
  font-weight: 400;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.mockup-column1 {
  gap: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.mockup-button10 {
  gap: 16px;
  display: flex;
  padding: 8px 20px;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.029999999329447746) ;
  align-items: center;
  border-color: rgba(20, 20, 20, 0.10000000149011612);
  border-style: solid;
  border-width: 1px;
  border-radius: 6px;
  justify-content: center;
  background-color: rgba(255, 255, 255, 1);
}
.mockup-text169 {
  color: rgba(20, 20, 20, 1);
  height: auto;
  font-size: 16px;
  font-style: Medium;
  text-align: left;
  font-family: DM Sans;
  font-weight: 500;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.mockup-button11 {
  gap: 8px;
  display: flex;
  padding: 8px 20px;
  box-sizing: content-box;
  align-items: center;
  border-radius: 4px;
  justify-content: center;
  background-color: rgba(53, 117, 226, 1);
}
.mockup-text171 {
  color: var(--dl-color-default-white);
  height: auto;
  font-size: 16px;
  font-style: Medium;
  text-align: left;
  font-family: DM Sans;
  font-weight: 500;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
@media(max-width: 1600px) {
  .mockup-content {
    width: auto;
    position: 0px;
    padding-left: 64px;
    padding-right: 64px;
  }
  .mockup-clock {
    width: 16px;
    height: 16px;
  }
  .mockup-clock1 {
    width: 16px;
    height: 16px;
  }
  .mockup-text088 {
    color: rgba(20, 20, 20, 0.698);
    font-size: 16px;
    font-family: DM Sans;
    font-weight: 400;
    line-height: 150%;
    text-decoration: none;
  }
  .mockup-clock2 {
    width: 16px;
    height: 16px;
  }
  .mockup-text099 {
    color: rgba(20, 20, 20, 0.698);
    font-size: 16px;
    font-family: DM Sans;
    font-weight: 400;
    line-height: 150%;
    text-decoration: none;
  }
  .mockup-clock3 {
    width: 16px;
    height: 16px;
  }
  .mockup-text110 {
    color: rgba(20, 20, 20, 0.698);
    font-size: 16px;
    font-family: DM Sans;
    font-weight: 400;
    line-height: 150%;
    text-decoration: none;
  }
  .mockup-clock4 {
    width: 16px;
    height: 16px;
  }
  .mockup-text121 {
    color: rgba(20, 20, 20, 0.698);
    font-size: 16px;
    font-family: DM Sans;
    font-weight: 400;
    line-height: 150%;
    text-decoration: none;
  }
  .mockup-clock5 {
    width: 0px;
    height: 0px;
  }
  .mockup-clock6 {
    width: 16px;
    height: 16px;
  }
  .mockup-text132 {
    color: rgba(20, 20, 20, 0.698);
    font-size: 16px;
    font-family: DM Sans;
    font-weight: 400;
    line-height: 150%;
    text-decoration: none;
  }
  .mockup-frame12 {
    width: auto;
    height: auto;
  }
  .mockup-navbar {
    top: 0px;
    flex: 1;
    left: 0px;
    right: 0px;
    width: 1592px;
    height: var(--dl-size-size-medium);
    margin: auto;
  }
  .mockup-text164 {
    color: rgb(20, 20, 20);
    font-size: 24px;
    text-align: center;
    font-family: DM Sans;
    font-weight: bold;
    line-height: 150%;
    text-transform: capitalize;
    text-decoration: none;
  }
  .mockup-text165 {
    color: rgb(20, 20, 20);
    font-size: 18px;
    text-align: center;
    font-family: DM Sans;
    font-weight: 400;
    line-height: 150%;
    text-transform: capitalize;
    text-decoration: none;
  }
  .mockup-text166 {
    color: rgb(20, 20, 20);
    font-size: 18px;
    text-align: center;
    font-family: DM Sans;
    font-weight: 400;
    line-height: 150%;
    text-transform: capitalize;
    text-decoration: none;
  }
  .mockup-text167 {
    color: rgb(20, 20, 20);
    font-size: 18px;
    text-align: center;
    text-transform: capitalize;
  }
  .mockup-text168 {
    color: rgb(20, 20, 20);
    font-size: 18px;
    text-align: center;
    font-family: DM Sans;
    font-weight: 400;
    line-height: 150%;
    text-transform: capitalize;
    text-decoration: none;
  }
  .mockup-text169 {
    font-size: 18px;
    text-align: center;
    text-transform: capitalize;
  }
  .mockup-text171 {
    font-size: 18px;
    text-align: center;
    font-weight: bold;
    text-transform: capitalize;
  }
}
@media(max-width: 1200px) {
  .mockup-content {
    flex: 1;
    width: auto;
  }
  .mockup-frame12 {
    flex: 1;
  }
  .mockup-navbar {
    flex: 1;
    width: 1200px;
  }
}
