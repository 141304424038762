.jobs-list-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.jobs-list-navbar {
  top: 0px;
  left: 0px;
  width: 1440px;
  height: 72px;
  display: flex;
  padding: 0 64px;
  position: absolute;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-default-white);
}
.jobs-list-container1 {
  gap: 32px;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  justify-content: space-between;
}
.jobs-list-frame47 {
  gap: 12px;
  display: flex;
  align-items: center;
}
.jobs-list-group3 {
  width: 29px;
  height: 29px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 1;
}
.jobs-list-ellipse8 {
  top: 0px;
  left: 0px;
  width: 24px;
  height: 24px;
  position: absolute;
}
.jobs-list-ellipse9 {
  top: 4.971428394317627px;
  left: 4.971428394317627px;
  width: 24px;
  height: 24px;
  position: absolute;
}
.jobs-list-text {
  color: rgba(20, 20, 20, 1);
  height: auto;
  font-size: 24px;
  font-style: Regular;
  text-align: left;
  font-family: DM Sans;
  font-weight: 400;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.jobs-list-column {
  gap: 48px;
  display: flex;
  overflow: hidden;
  align-items: flex-start;
}
.jobs-list-text001 {
  color: rgba(20, 20, 20, 1);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: DM Sans;
  font-weight: 400;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.jobs-list-text002 {
  color: rgba(20, 20, 20, 1);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: DM Sans;
  font-weight: 400;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.jobs-list-text003 {
  color: rgba(20, 20, 20, 1);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: DM Sans;
  font-weight: 400;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.jobs-list-text004 {
  color: rgba(20, 20, 20, 1);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: DM Sans;
  font-weight: 400;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.jobs-list-column1 {
  gap: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.jobs-list-button {
  gap: 16px;
  display: flex;
  padding: 8px 20px;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.029999999329447746) ;
  align-items: center;
  border-color: rgba(20, 20, 20, 0.10000000149011612);
  border-style: solid;
  border-width: 1px;
  border-radius: 6px;
  justify-content: center;
  background-color: rgba(255, 255, 255, 1);
}
.jobs-list-text005 {
  color: rgba(20, 20, 20, 1);
  height: auto;
  font-size: 16px;
  font-style: Medium;
  text-align: left;
  font-family: DM Sans;
  font-weight: 500;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.jobs-list-button01 {
  gap: 8px;
  display: flex;
  padding: 8px 20px;
  box-sizing: content-box;
  align-items: center;
  border-radius: 4px;
  justify-content: center;
  background-color: rgba(53, 117, 226, 1);
}
.jobs-list-text007 {
  color: var(--dl-color-default-white);
  height: auto;
  font-size: 16px;
  font-style: Medium;
  text-align: left;
  font-family: DM Sans;
  font-weight: 500;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.jobs-list-frame11 {
  gap: 8px;
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.jobs-list-container2 {
  flex: 0 0 auto;
  width: 100%;
  height: 100px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}
.jobs-list-text009 {
  color: rgba(0, 0, 0, 0.699999988079071);
  height: auto;
  font-size: 18px;
  font-style: Regular;
  text-align: left;
  font-family: DM Sans;
  font-weight: 400;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.jobs-list-text010 {
  height: auto;
  font-size: 56px;
  font-style: Bold;
  text-align: left;
  font-family: DM Sans;
  font-weight: 700;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.jobs-list-container3 {
  flex: 0 0 auto;
  width: 100%;
  height: 100px;
  display: flex;
  align-items: flex-start;
}
.jobs-list-frame9 {
  gap: 16px;
  display: flex;
  padding: 18px;
  overflow: hidden;
  flex-grow: 1;
  align-items: center;
  border-color: rgba(20, 20, 20, 0.10000000149011612);
  border-style: solid;
  border-width: 1px;
  border-radius: 4px 0 0 4px;
  background-color: rgba(255, 255, 255, 1);
}
.jobs-list-magnifying-glass {
  width: 24px;
  height: 24px;
}
.jobs-list-text014 {
  color: rgba(20, 20, 20, 0.5);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: DM Sans;
  font-weight: 400;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.jobs-list-frame14 {
  gap: 16px;
  width: 420px;
  display: flex;
  padding: 18px;
  overflow: hidden;
  align-items: center;
  flex-shrink: 0;
  border-color: rgba(20, 20, 20, 0.10000000149011612);
  border-style: solid;
  border-width: 1px;
  background-color: rgba(255, 255, 255, 1);
}
.jobs-list-map-pin-line {
  width: 24px;
  height: 24px;
}
.jobs-list-text016 {
  color: rgba(20, 20, 20, 0.5);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: DM Sans;
  font-weight: 400;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.jobs-list-button02 {
  gap: 8px;
  display: flex;
  padding: 18px 40px;
  box-sizing: content-box;
  align-items: center;
  border-radius: 0 4px 4px 0;
  justify-content: center;
  background-color: rgba(53, 117, 226, 1);
}
.jobs-list-text018 {
  color: var(--dl-color-default-white);
  height: auto;
  font-size: 16px;
  font-style: Medium;
  text-align: left;
  font-family: DM Sans;
  font-weight: 500;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.jobs-list-container4 {
  flex: 0 0 auto;
  width: 100%;
  height: 100px;
  display: flex;
  align-items: flex-start;
}
.jobs-list-content {
  gap: 8px;
  top: 451px;
  left: 0px;
  width: 1440px;
  display: flex;
  padding: 48px 64px 64px;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
  background-color: rgba(250, 250, 250, 1);
}
.jobs-list-frame15 {
  gap: 32px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.jobs-list-filter {
  gap: 24px;
  width: 250px;
  display: flex;
  padding: 24px;
  overflow: hidden;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.029999999329447746) ;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(20, 20, 20, 0.05000000074505806);
  border-style: solid;
  border-width: 1px;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 1);
}
.jobs-list-frame36 {
  gap: 16px;
  width: 202px;
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.jobs-list-text020 {
  color: rgba(20, 20, 20, 1);
  height: auto;
  font-size: 20px;
  font-style: Bold;
  text-align: left;
  font-family: DM Sans;
  font-weight: 700;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.jobs-list-frame35 {
  gap: 12px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.jobs-list-text022 {
  color: rgba(20, 20, 20, 1);
  height: auto;
  font-size: 18px;
  align-self: stretch;
  font-style: Medium;
  text-align: left;
  font-family: DM Sans;
  font-weight: 500;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.jobs-list-frame37 {
  gap: 12px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.jobs-list-text025 {
  color: rgba(20, 20, 20, 1);
  height: auto;
  font-size: 18px;
  align-self: stretch;
  font-style: Medium;
  text-align: left;
  font-family: DM Sans;
  font-weight: 500;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.jobs-list-frame43 {
  gap: -1px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.jobs-list-frame40 {
  gap: 8px;
  width: 68px;
  display: flex;
  padding: 8px;
  overflow: hidden;
  flex-grow: 1;
  align-items: center;
  flex-shrink: 0;
  border-color: rgba(20, 20, 20, 0.10000000149011612);
  border-style: solid;
  border-width: 1px;
  border-radius: 2px 0 0 2px;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(255, 255, 255, 1);
}
.jobs-list-text027 {
  color: rgba(57, 63, 71, 1);
  height: auto;
  font-size: 13px;
  font-style: Regular;
  text-align: left;
  font-family: DM Sans;
  font-weight: 400;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.jobs-list-frame41 {
  gap: 8px;
  width: 68px;
  display: flex;
  padding: 8px;
  overflow: hidden;
  flex-grow: 1;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(20, 20, 20, 0.10000000149011612);
  border-style: solid;
  border-width: 1px;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 1);
}
.jobs-list-text029 {
  color: rgba(57, 63, 71, 1);
  height: auto;
  font-size: 13px;
  font-style: Regular;
  text-align: left;
  font-family: DM Sans;
  font-weight: 400;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.jobs-list-frame42 {
  gap: 8px;
  width: 68px;
  display: flex;
  padding: 8px;
  overflow: hidden;
  flex-grow: 1;
  align-items: center;
  flex-shrink: 0;
  border-color: rgba(53, 117, 226, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 0 2px 2px 0;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(53, 117, 226, 0.20000000298023224);
}
.jobs-list-text031 {
  color: rgba(53, 117, 226, 1);
  height: auto;
  font-size: 13px;
  font-style: Medium;
  text-align: left;
  font-family: DM Sans;
  font-weight: 500;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.jobs-list-frame34 {
  gap: 8px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.jobs-list-frame31 {
  gap: 8px;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
}
.jobs-list-group1 {
  width: 16px;
  height: 16px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 1;
}
.jobs-list-ellipse6 {
  top: 0px;
  left: 0px;
  width: 16px;
  height: 16px;
  position: absolute;
  box-sizing: content-box;
}
.jobs-list-ellipse7 {
  top: 4px;
  left: 4px;
  width: 8px;
  height: 8px;
  position: absolute;
}
.jobs-list-text033 {
  color: rgba(57, 63, 71, 1);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: DM Sans;
  font-weight: 400;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.jobs-list-frame32 {
  gap: 8px;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
}
.jobs-list-ellipse5 {
  width: 16px;
  height: 16px;
  border-color: rgba(20, 20, 20, 0.30000001192092896);
  border-style: solid;
  border-width: 1px;
}
.jobs-list-text035 {
  color: rgba(57, 63, 71, 1);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: DM Sans;
  font-weight: 400;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.jobs-list-frame33 {
  gap: 8px;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
}
.jobs-list-ellipse51 {
  width: 16px;
  height: 16px;
  border-color: rgba(20, 20, 20, 0.30000001192092896);
  border-style: solid;
  border-width: 1px;
}
.jobs-list-text037 {
  color: rgba(57, 63, 71, 1);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: DM Sans;
  font-weight: 400;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.jobs-list-frame341 {
  gap: 8px;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
}
.jobs-list-ellipse52 {
  width: 16px;
  height: 16px;
  border-color: rgba(20, 20, 20, 0.30000001192092896);
  border-style: solid;
  border-width: 1px;
}
.jobs-list-text039 {
  color: rgba(57, 63, 71, 1);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: DM Sans;
  font-weight: 400;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.jobs-list-frame351 {
  gap: 8px;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
}
.jobs-list-ellipse53 {
  width: 16px;
  height: 16px;
  border-color: rgba(20, 20, 20, 0.30000001192092896);
  border-style: solid;
  border-width: 1px;
}
.jobs-list-text041 {
  color: rgba(57, 63, 71, 1);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: DM Sans;
  font-weight: 400;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.jobs-list-frame361 {
  gap: 12px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.jobs-list-text043 {
  color: rgba(20, 20, 20, 1);
  height: auto;
  font-size: 18px;
  align-self: stretch;
  font-style: Medium;
  text-align: left;
  font-family: DM Sans;
  font-weight: 500;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.jobs-list-frame342 {
  gap: 8px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.jobs-list-frame311 {
  gap: 8px;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
}
.jobs-list-group11 {
  width: 16px;
  height: 16px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 1;
}
.jobs-list-ellipse61 {
  top: 0px;
  left: 0px;
  width: 16px;
  height: 16px;
  position: absolute;
  box-sizing: content-box;
}
.jobs-list-ellipse71 {
  top: 4px;
  left: 4px;
  width: 8px;
  height: 8px;
  position: absolute;
}
.jobs-list-text044 {
  color: rgba(57, 63, 71, 1);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: DM Sans;
  font-weight: 400;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.jobs-list-frame362 {
  gap: 12px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.jobs-list-text045 {
  color: rgba(20, 20, 20, 1);
  height: auto;
  font-size: 18px;
  align-self: stretch;
  font-style: Medium;
  text-align: left;
  font-family: DM Sans;
  font-weight: 500;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.jobs-list-frame343 {
  gap: 8px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.jobs-list-frame312 {
  gap: 8px;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
}
.jobs-list-group12 {
  width: 16px;
  height: 16px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 1;
}
.jobs-list-ellipse62 {
  top: 0px;
  left: 0px;
  width: 16px;
  height: 16px;
  position: absolute;
  box-sizing: content-box;
}
.jobs-list-ellipse72 {
  top: 4px;
  left: 4px;
  width: 8px;
  height: 8px;
  position: absolute;
}
.jobs-list-text046 {
  color: rgba(57, 63, 71, 1);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: DM Sans;
  font-weight: 400;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.jobs-list-frame38 {
  gap: 12px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.jobs-list-text047 {
  color: rgba(20, 20, 20, 1);
  height: auto;
  font-size: 18px;
  align-self: stretch;
  font-style: Medium;
  text-align: left;
  font-family: DM Sans;
  font-weight: 500;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.jobs-list-frame344 {
  gap: 8px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.jobs-list-frame313 {
  gap: 8px;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
}
.jobs-list-group13 {
  width: 16px;
  height: 16px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 1;
}
.jobs-list-ellipse63 {
  top: 0px;
  left: 0px;
  width: 16px;
  height: 16px;
  position: absolute;
  box-sizing: content-box;
}
.jobs-list-ellipse73 {
  top: 4px;
  left: 4px;
  width: 8px;
  height: 8px;
  position: absolute;
}
.jobs-list-text049 {
  color: rgba(57, 63, 71, 1);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: DM Sans;
  font-weight: 400;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.jobs-list-frame321 {
  gap: 8px;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
}
.jobs-list-ellipse54 {
  width: 16px;
  height: 16px;
  border-color: rgba(20, 20, 20, 0.30000001192092896);
  border-style: solid;
  border-width: 1px;
}
.jobs-list-text050 {
  color: rgba(57, 63, 71, 1);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: DM Sans;
  font-weight: 400;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.jobs-list-frame331 {
  gap: 8px;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
}
.jobs-list-ellipse55 {
  width: 16px;
  height: 16px;
  border-color: rgba(20, 20, 20, 0.30000001192092896);
  border-style: solid;
  border-width: 1px;
}
.jobs-list-text051 {
  color: rgba(57, 63, 71, 1);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: DM Sans;
  font-weight: 400;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.jobs-list-frame332 {
  gap: 8px;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
}
.jobs-list-ellipse56 {
  width: 16px;
  height: 16px;
  border-color: rgba(20, 20, 20, 0.30000001192092896);
  border-style: solid;
  border-width: 1px;
}
.jobs-list-text052 {
  color: rgba(57, 63, 71, 1);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: DM Sans;
  font-weight: 400;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.jobs-list-frame333 {
  gap: 8px;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
}
.jobs-list-ellipse57 {
  width: 16px;
  height: 16px;
  border-color: rgba(20, 20, 20, 0.30000001192092896);
  border-style: solid;
  border-width: 1px;
}
.jobs-list-text053 {
  color: rgba(57, 63, 71, 1);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: DM Sans;
  font-weight: 400;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.jobs-list-frame39 {
  gap: 12px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.jobs-list-text054 {
  color: rgba(20, 20, 20, 1);
  height: auto;
  font-size: 18px;
  align-self: stretch;
  font-style: Medium;
  text-align: left;
  font-family: DM Sans;
  font-weight: 500;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.jobs-list-frame345 {
  gap: 8px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.jobs-list-frame314 {
  gap: 8px;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
}
.jobs-list-group4 {
  width: 16px;
  height: 16.0078125px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 1;
}
.jobs-list-rectangle2 {
  top: 0px;
  left: 0px;
  width: 16px;
  height: 16px;
  position: absolute;
  border-radius: 2px;
}
.jobs-list-check {
  top: 0.0078125px;
  left: 0px;
  width: 16px;
  height: 16px;
  position: absolute;
}
.jobs-list-text056 {
  color: rgba(57, 63, 71, 1);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: DM Sans;
  font-weight: 400;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.jobs-list-frame322 {
  gap: 8px;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
}
.jobs-list-rectangle21 {
  width: 16px;
  height: 16px;
  border-color: rgba(20, 20, 20, 0.30000001192092896);
  border-style: solid;
  border-width: 1px;
  border-radius: 2px;
}
.jobs-list-text058 {
  color: rgba(57, 63, 71, 1);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: DM Sans;
  font-weight: 400;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.jobs-list-frame334 {
  gap: 8px;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
}
.jobs-list-rectangle3 {
  width: 16px;
  height: 16px;
  border-color: rgba(20, 20, 20, 0.30000001192092896);
  border-style: solid;
  border-width: 1px;
  border-radius: 2px;
}
.jobs-list-text060 {
  color: rgba(57, 63, 71, 1);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: DM Sans;
  font-weight: 400;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.jobs-list-cards {
  gap: 48px;
  width: 748px;
  display: flex;
  flex-grow: 1;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: flex-end;
}
.jobs-list-frame71 {
  gap: 16px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.jobs-list-frame46 {
  gap: 16px;
  width: 748px;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  justify-content: space-between;
}
.jobs-list-text062 {
  color: rgba(20, 20, 20, 1);
  height: auto;
  font-size: 32px;
  font-style: Bold;
  text-align: left;
  font-family: DM Sans;
  font-weight: 700;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.jobs-list-frame141 {
  gap: 16px;
  width: 281px;
  display: flex;
  padding: 12px;
  overflow: hidden;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.029999999329447746) ;
  align-items: center;
  flex-shrink: 0;
  border-color: rgba(20, 20, 20, 0.10000000149011612);
  border-style: solid;
  border-width: 1px;
  border-radius: 4px;
  justify-content: space-between;
  background-color: rgba(255, 255, 255, 1);
}
.jobs-list-frame45 {
  gap: 12px;
  display: flex;
  align-items: flex-start;
}
.jobs-list-funnel-simple {
  width: 24px;
  height: 24px;
}
.jobs-list-text064 {
  color: rgba(20, 20, 20, 1);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: DM Sans;
  font-weight: 400;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.jobs-list-caret-down {
  width: 16px;
  height: 16px;
}
.jobs-list-frame26 {
  gap: 24px;
  width: 748px;
  display: flex;
  padding: 24px;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.029999999329447746) ;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(20, 20, 20, 0.05000000074505806);
  border-style: solid;
  border-width: 1px;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 1);
}
.jobs-list-frame17 {
  width: 72px;
  height: 72px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: 4px;
  background-size: cover;
  background-image: url("/external/78c65453-bb9d-48e4-8691-3299afd8fa1e-wvyi-200h.png");
}
.jobs-list-frame23 {
  gap: 12px;
  width: 604px;
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.jobs-list-frame28 {
  gap: 6px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.jobs-list-container5 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.jobs-list-frame29 {
  gap: 4px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.jobs-list-frame27 {
  gap: 12px;
  display: flex;
  align-items: center;
}
.jobs-list-text066 {
  color: rgba(20, 20, 20, 1);
  height: auto;
  font-size: 18px;
  font-style: Regular;
  text-align: left;
  font-family: DM Sans;
  font-weight: 400;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.jobs-list-frame30 {
  gap: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.jobs-list-text068 {
  color: rgba(20, 20, 20, 1);
  height: auto;
  font-size: 24px;
  font-style: Medium;
  text-align: left;
  font-family: DM Sans;
  font-weight: 500;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.jobs-list-frame25 {
  gap: 4px;
  display: flex;
  padding: 2px 6px;
  overflow: hidden;
  align-items: center;
  border-radius: 3px;
  background-color: rgba(125, 90, 226, 0.10000000149011612);
}
.jobs-list-text070 {
  color: rgba(125, 90, 226, 1);
  height: auto;
  font-size: 12px;
  font-style: Medium;
  text-align: left;
  font-family: DM Sans;
  font-weight: 500;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.jobs-list-frame3001 {
  gap: 24px;
  display: flex;
  padding: 2px 0;
  align-items: center;
  justify-content: center;
}
.jobs-list-frame18 {
  gap: 6px;
  display: flex;
  align-items: center;
}
.jobs-list-map-pin-line1 {
  width: 16px;
  height: 16px;
}
.jobs-list-text072 {
  color: rgba(20, 20, 20, 0.699999988079071);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: DM Sans;
  font-weight: 400;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.jobs-list-ellipse3 {
  width: 2px;
  height: 2px;
}
.jobs-list-frame20 {
  gap: 6px;
  display: flex;
  align-items: center;
}
.jobs-list-text074 {
  color: rgba(20, 20, 20, 0.699999988079071);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: DM Sans;
  font-weight: 400;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.jobs-list-ellipse2 {
  width: 2px;
  height: 2px;
}
.jobs-list-frame21 {
  gap: 6px;
  display: flex;
  align-items: center;
}
.jobs-list-currency-dollar {
  width: 16px;
  height: 16px;
}
.jobs-list-text076 {
  color: rgba(20, 20, 20, 0.699999988079071);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: DM Sans;
  font-weight: 400;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.jobs-list-ellipse1 {
  width: 2px;
  height: 2px;
}
.jobs-list-frame22 {
  gap: 6px;
  display: flex;
  align-items: center;
}
.jobs-list-clock {
  width: 16px;
  height: 16px;
}
.jobs-list-text078 {
  color: rgba(20, 20, 20, 0.699999988079071);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: DM Sans;
  font-weight: 400;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.jobs-list-frame271 {
  gap: 24px;
  width: 748px;
  display: flex;
  padding: 24px;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.029999999329447746) ;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(20, 20, 20, 0.05000000074505806);
  border-style: solid;
  border-width: 1px;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 1);
}
.jobs-list-frame171 {
  width: 72px;
  height: 72px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: 4px;
  background-size: cover;
  background-image: url("/external/76e45941-25d0-4c47-b286-55dfa6ceb1a8-gjkd-200h.png");
}
.jobs-list-frame231 {
  gap: 12px;
  width: 604px;
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.jobs-list-frame281 {
  gap: 6px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.jobs-list-frame291 {
  gap: 4px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.jobs-list-frame272 {
  gap: 12px;
  display: flex;
  align-items: center;
}
.jobs-list-text080 {
  color: rgba(20, 20, 20, 1);
  height: auto;
  font-size: 18px;
  font-style: Regular;
  text-align: left;
  font-family: DM Sans;
  font-weight: 400;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.jobs-list-frame3002 {
  gap: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.jobs-list-text082 {
  color: rgba(20, 20, 20, 1);
  height: auto;
  font-size: 24px;
  font-style: Medium;
  text-align: left;
  font-family: DM Sans;
  font-weight: 500;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.jobs-list-frame3003 {
  gap: 24px;
  display: flex;
  padding: 2px 0;
  align-items: center;
  justify-content: center;
}
.jobs-list-frame181 {
  gap: 6px;
  display: flex;
  align-items: center;
}
.jobs-list-map-pin-line2 {
  width: 16px;
  height: 16px;
}
.jobs-list-text084 {
  color: rgba(20, 20, 20, 0.699999988079071);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: DM Sans;
  font-weight: 400;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.jobs-list-ellipse31 {
  width: 2px;
  height: 2px;
}
.jobs-list-frame201 {
  gap: 6px;
  display: flex;
  align-items: center;
}
.jobs-list-text086 {
  color: rgba(20, 20, 20, 0.699999988079071);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: DM Sans;
  font-weight: 400;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.jobs-list-ellipse21 {
  width: 2px;
  height: 2px;
}
.jobs-list-frame211 {
  gap: 6px;
  display: flex;
  align-items: center;
}
.jobs-list-currency-dollar1 {
  width: 16px;
  height: 16px;
}
.jobs-list-text088 {
  color: rgba(20, 20, 20, 0.699999988079071);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: DM Sans;
  font-weight: 400;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.jobs-list-ellipse11 {
  width: 2px;
  height: 2px;
}
.jobs-list-frame221 {
  gap: 6px;
  display: flex;
  align-items: center;
}
.jobs-list-clock1 {
  width: 16px;
  height: 16px;
}
.jobs-list-text090 {
  color: rgba(20, 20, 20, 0.699999988079071);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: DM Sans;
  font-weight: 400;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.jobs-list-frame48 {
  gap: 24px;
  width: 748px;
  display: flex;
  padding: 24px;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.029999999329447746) ;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(20, 20, 20, 0.05000000074505806);
  border-style: solid;
  border-width: 1px;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 1);
}
.jobs-list-frame172 {
  width: 72px;
  height: 72px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: 4px;
  background-size: cover;
  background-image: url("/external/88d29e18-950a-443d-afd7-e92338e7cfe8-xzgc-200h.png");
}
.jobs-list-frame232 {
  gap: 12px;
  width: 604px;
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.jobs-list-frame282 {
  gap: 6px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.jobs-list-frame292 {
  gap: 4px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.jobs-list-frame273 {
  gap: 12px;
  display: flex;
  align-items: center;
}
.jobs-list-text091 {
  color: rgba(20, 20, 20, 1);
  height: auto;
  font-size: 18px;
  font-style: Regular;
  text-align: left;
  font-family: DM Sans;
  font-weight: 400;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.jobs-list-frame3004 {
  gap: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.jobs-list-text093 {
  color: rgba(20, 20, 20, 1);
  height: auto;
  font-size: 24px;
  font-style: Medium;
  text-align: left;
  font-family: DM Sans;
  font-weight: 500;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.jobs-list-frame3005 {
  gap: 24px;
  display: flex;
  padding: 2px 0;
  align-items: center;
  justify-content: center;
}
.jobs-list-frame182 {
  gap: 6px;
  display: flex;
  align-items: center;
}
.jobs-list-map-pin-line3 {
  width: 16px;
  height: 16px;
}
.jobs-list-text095 {
  color: rgba(20, 20, 20, 0.699999988079071);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: DM Sans;
  font-weight: 400;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.jobs-list-ellipse32 {
  width: 2px;
  height: 2px;
}
.jobs-list-frame202 {
  gap: 6px;
  display: flex;
  align-items: center;
}
.jobs-list-text097 {
  color: rgba(20, 20, 20, 0.699999988079071);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: DM Sans;
  font-weight: 400;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.jobs-list-ellipse22 {
  width: 2px;
  height: 2px;
}
.jobs-list-frame212 {
  gap: 6px;
  display: flex;
  align-items: center;
}
.jobs-list-currency-dollar2 {
  width: 16px;
  height: 16px;
}
.jobs-list-text099 {
  color: rgba(20, 20, 20, 0.699999988079071);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: DM Sans;
  font-weight: 400;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.jobs-list-ellipse12 {
  width: 2px;
  height: 2px;
}
.jobs-list-frame222 {
  gap: 6px;
  display: flex;
  align-items: center;
}
.jobs-list-clock2 {
  width: 16px;
  height: 16px;
}
.jobs-list-text101 {
  color: rgba(20, 20, 20, 0.699999988079071);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: DM Sans;
  font-weight: 400;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.jobs-list-frame251 {
  gap: 24px;
  width: 748px;
  display: flex;
  padding: 24px;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.029999999329447746) ;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(20, 20, 20, 0.05000000074505806);
  border-style: solid;
  border-width: 1px;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 1);
}
.jobs-list-frame173 {
  width: 72px;
  height: 72px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: 4px;
  background-size: cover;
  background-image: url("/external/cba22aab-1c48-43e8-a9b2-e3c784bef35a-1uij-200h.png");
}
.jobs-list-frame233 {
  gap: 12px;
  width: 604px;
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.jobs-list-frame283 {
  gap: 6px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.jobs-list-frame293 {
  gap: 4px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.jobs-list-frame274 {
  gap: 12px;
  display: flex;
  align-items: center;
}
.jobs-list-text102 {
  color: rgba(20, 20, 20, 1);
  height: auto;
  font-size: 18px;
  font-style: Regular;
  text-align: left;
  font-family: DM Sans;
  font-weight: 400;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.jobs-list-frame3006 {
  gap: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.jobs-list-text104 {
  color: rgba(20, 20, 20, 1);
  height: auto;
  font-size: 24px;
  font-style: Medium;
  text-align: left;
  font-family: DM Sans;
  font-weight: 500;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.jobs-list-frame3007 {
  gap: 24px;
  display: flex;
  padding: 2px 0;
  align-items: center;
  justify-content: center;
}
.jobs-list-frame183 {
  gap: 6px;
  display: flex;
  align-items: center;
}
.jobs-list-map-pin-line4 {
  width: 16px;
  height: 16px;
}
.jobs-list-text106 {
  color: rgba(20, 20, 20, 0.699999988079071);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: DM Sans;
  font-weight: 400;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.jobs-list-ellipse33 {
  width: 2px;
  height: 2px;
}
.jobs-list-frame203 {
  gap: 6px;
  display: flex;
  align-items: center;
}
.jobs-list-text108 {
  color: rgba(20, 20, 20, 0.699999988079071);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: DM Sans;
  font-weight: 400;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.jobs-list-ellipse23 {
  width: 2px;
  height: 2px;
}
.jobs-list-frame213 {
  gap: 6px;
  display: flex;
  align-items: center;
}
.jobs-list-currency-dollar3 {
  width: 16px;
  height: 16px;
}
.jobs-list-text110 {
  color: rgba(20, 20, 20, 0.699999988079071);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: DM Sans;
  font-weight: 400;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.jobs-list-ellipse13 {
  width: 2px;
  height: 2px;
}
.jobs-list-frame223 {
  gap: 6px;
  display: flex;
  align-items: center;
}
.jobs-list-clock3 {
  width: 16px;
  height: 16px;
}
.jobs-list-text112 {
  color: rgba(20, 20, 20, 0.699999988079071);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: DM Sans;
  font-weight: 400;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.jobs-list-frame471 {
  gap: 24px;
  width: 748px;
  display: flex;
  padding: 24px;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.029999999329447746) ;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(20, 20, 20, 0.05000000074505806);
  border-style: solid;
  border-width: 1px;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 1);
}
.jobs-list-frame174 {
  width: 72px;
  height: 72px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: 4px;
  background-size: cover;
  background-image: url("/external/2f15275e-4d9e-412f-aa40-e9aea66e68d3-nltq-200h.png");
}
.jobs-list-frame234 {
  gap: 12px;
  width: 604px;
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.jobs-list-frame284 {
  gap: 6px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.jobs-list-frame294 {
  gap: 4px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.jobs-list-frame275 {
  gap: 12px;
  display: flex;
  align-items: center;
}
.jobs-list-text113 {
  color: rgba(20, 20, 20, 1);
  height: auto;
  font-size: 18px;
  font-style: Regular;
  text-align: left;
  font-family: DM Sans;
  font-weight: 400;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.jobs-list-frame3008 {
  gap: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.jobs-list-text115 {
  color: rgba(20, 20, 20, 1);
  height: auto;
  font-size: 24px;
  font-style: Medium;
  text-align: left;
  font-family: DM Sans;
  font-weight: 500;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.jobs-list-frame3009 {
  gap: 24px;
  display: flex;
  padding: 2px 0;
  align-items: center;
  justify-content: center;
}
.jobs-list-frame184 {
  gap: 6px;
  display: flex;
  align-items: center;
}
.jobs-list-map-pin-line5 {
  width: 16px;
  height: 16px;
}
.jobs-list-text117 {
  color: rgba(20, 20, 20, 0.699999988079071);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: DM Sans;
  font-weight: 400;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.jobs-list-ellipse34 {
  width: 2px;
  height: 2px;
}
.jobs-list-frame204 {
  gap: 6px;
  display: flex;
  align-items: center;
}
.jobs-list-text119 {
  color: rgba(20, 20, 20, 0.699999988079071);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: DM Sans;
  font-weight: 400;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.jobs-list-ellipse24 {
  width: 2px;
  height: 2px;
}
.jobs-list-frame214 {
  gap: 6px;
  display: flex;
  align-items: center;
}
.jobs-list-currency-dollar4 {
  width: 16px;
  height: 16px;
}
.jobs-list-text121 {
  color: rgba(20, 20, 20, 0.699999988079071);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: DM Sans;
  font-weight: 400;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.jobs-list-ellipse14 {
  width: 2px;
  height: 2px;
}
.jobs-list-frame224 {
  gap: 6px;
  display: flex;
  align-items: center;
}
.jobs-list-clock4 {
  width: 16px;
  height: 16px;
}
.jobs-list-text123 {
  color: rgba(20, 20, 20, 0.699999988079071);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: DM Sans;
  font-weight: 400;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.jobs-list-frame24 {
  gap: 24px;
  width: 748px;
  display: flex;
  padding: 24px;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.029999999329447746) ;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(20, 20, 20, 0.05000000074505806);
  border-style: solid;
  border-width: 1px;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 1);
}
.jobs-list-frame175 {
  width: 72px;
  height: 72px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: 4px;
  background-size: cover;
  background-image: url("/external/d1e63373-7a47-4023-bc19-ccac033f48b7-j76-200h.png");
}
.jobs-list-frame235 {
  gap: 12px;
  width: 604px;
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.jobs-list-frame285 {
  gap: 6px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.jobs-list-frame295 {
  gap: 4px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.jobs-list-frame276 {
  gap: 12px;
  display: flex;
  align-items: center;
}
.jobs-list-text124 {
  color: rgba(20, 20, 20, 1);
  height: auto;
  font-size: 18px;
  font-style: Regular;
  text-align: left;
  font-family: DM Sans;
  font-weight: 400;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.jobs-list-frame3010 {
  gap: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.jobs-list-text126 {
  color: rgba(20, 20, 20, 1);
  height: auto;
  font-size: 24px;
  font-style: Medium;
  text-align: left;
  font-family: DM Sans;
  font-weight: 500;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.jobs-list-frame225 {
  gap: 24px;
  display: flex;
  padding: 2px 0;
  align-items: center;
  justify-content: center;
}
.jobs-list-frame185 {
  gap: 6px;
  display: flex;
  align-items: center;
}
.jobs-list-map-pin-line6 {
  width: 16px;
  height: 16px;
}
.jobs-list-text128 {
  color: rgba(20, 20, 20, 0.699999988079071);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: DM Sans;
  font-weight: 400;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.jobs-list-ellipse35 {
  width: 2px;
  height: 2px;
}
.jobs-list-frame205 {
  gap: 6px;
  display: flex;
  align-items: center;
}
.jobs-list-clock5 {
  width: 16px;
  height: 16px;
}
.jobs-list-text130 {
  color: rgba(20, 20, 20, 0.699999988079071);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: DM Sans;
  font-weight: 400;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.jobs-list-ellipse25 {
  width: 2px;
  height: 2px;
}
.jobs-list-frame215 {
  gap: 6px;
  display: flex;
  align-items: center;
}
.jobs-list-currency-dollar5 {
  width: 16px;
  height: 16px;
}
.jobs-list-text132 {
  color: rgba(20, 20, 20, 0.699999988079071);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: DM Sans;
  font-weight: 400;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.jobs-list-ellipse15 {
  width: 2px;
  height: 2px;
}
.jobs-list-frame226 {
  gap: 6px;
  display: flex;
  align-items: center;
}
.jobs-list-clock6 {
  width: 16px;
  height: 16px;
}
.jobs-list-text134 {
  color: rgba(20, 20, 20, 0.699999988079071);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: DM Sans;
  font-weight: 400;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.jobs-list-pagination {
  gap: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.jobs-list-button03 {
  gap: 4px;
  width: 40px;
  height: 40px;
  display: flex;
  padding: 8px 16px;
  align-items: center;
  flex-shrink: 0;
  border-color: rgba(17, 17, 17, 0.10000000149011612);
  border-style: solid;
  border-width: 1px;
  border-radius: 2px;
  justify-content: center;
  background-color: rgba(255, 255, 255, 1);
}
.jobs-list-chevron-down {
  width: 16px;
  height: 16px;
}
.jobs-list-button04 {
  gap: 4px;
  width: 40px;
  height: 40px;
  display: flex;
  padding: 8px 16px;
  align-items: center;
  flex-shrink: 0;
  border-radius: 2px;
  justify-content: center;
  background-color: rgba(53, 117, 226, 1);
}
.jobs-list-text135 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  font-size: 16px;
  font-style: Bold;
  text-align: left;
  font-family: DM Sans;
  font-weight: 700;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.jobs-list-button05 {
  gap: 4px;
  width: 40px;
  height: 40px;
  display: flex;
  padding: 8px 16px;
  align-items: center;
  flex-shrink: 0;
  border-color: rgba(17, 17, 17, 0.10000000149011612);
  border-style: solid;
  border-width: 1px;
  border-radius: 2px;
  justify-content: center;
  background-color: rgba(255, 255, 255, 1);
}
.jobs-list-text136 {
  color: rgba(17, 17, 17, 1);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: DM Sans;
  font-weight: 400;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.jobs-list-button06 {
  gap: 4px;
  width: 40px;
  height: 40px;
  display: flex;
  padding: 8px 16px;
  align-items: center;
  flex-shrink: 0;
  border-color: rgba(17, 17, 17, 0.10000000149011612);
  border-style: solid;
  border-width: 1px;
  border-radius: 2px;
  justify-content: center;
  background-color: rgba(255, 255, 255, 1);
}
.jobs-list-text137 {
  color: rgba(17, 17, 17, 1);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: DM Sans;
  font-weight: 400;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.jobs-list-button07 {
  gap: 4px;
  width: 40px;
  height: 40px;
  display: flex;
  padding: 8px 16px;
  align-items: center;
  flex-shrink: 0;
  border-color: rgba(17, 17, 17, 0.10000000149011612);
  border-style: solid;
  border-width: 1px;
  border-radius: 2px;
  justify-content: center;
  background-color: rgba(255, 255, 255, 1);
}
.jobs-list-text138 {
  color: rgba(17, 17, 17, 1);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: DM Sans;
  font-weight: 400;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.jobs-list-button08 {
  gap: 4px;
  width: 40px;
  height: 40px;
  display: flex;
  padding: 8px 16px;
  align-items: center;
  flex-shrink: 0;
  border-color: rgba(17, 17, 17, 0.10000000149011612);
  border-style: solid;
  border-width: 1px;
  border-radius: 2px;
  justify-content: center;
  background-color: rgba(255, 255, 255, 1);
}
.jobs-list-text139 {
  color: rgba(17, 17, 17, 1);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: DM Sans;
  font-weight: 400;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.jobs-list-button09 {
  gap: 4px;
  width: 40px;
  height: 40px;
  display: flex;
  padding: 8px 16px;
  align-items: center;
  flex-shrink: 0;
  border-color: rgba(17, 17, 17, 0.10000000149011612);
  border-style: solid;
  border-width: 1px;
  border-radius: 2px;
  justify-content: center;
  background-color: rgba(255, 255, 255, 1);
}
.jobs-list-chevron-down1 {
  width: 16px;
  height: 16px;
}
.jobs-list-moreoption {
  gap: 16px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.jobs-list-frame16 {
  gap: 16px;
  width: 250px;
  display: flex;
  padding: 24px;
  overflow: hidden;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.029999999329447746) ;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(20, 20, 20, 0.05000000074505806);
  border-style: solid;
  border-width: 1px;
  border-radius: 8px;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 1);
}
.jobs-list-frame363 {
  gap: 16px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.jobs-list-text140 {
  color: rgba(20, 20, 20, 1);
  height: auto;
  font-size: 20px;
  align-self: stretch;
  font-style: Bold;
  text-align: left;
  font-family: DM Sans;
  font-weight: 700;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.jobs-list-frame371 {
  gap: 16px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.jobs-list-text141 {
  color: rgba(20, 20, 20, 0.800000011920929);
  height: auto;
  font-size: 14px;
  align-self: stretch;
  font-style: Regular;
  text-align: left;
  font-family: DM Sans;
  font-weight: 400;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.jobs-list-frame72 {
  gap: 12px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.jobs-list-frame142 {
  gap: 16px;
  width: 202px;
  display: flex;
  padding: 8px 12px;
  overflow: hidden;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.029999999329447746) ;
  align-items: center;
  flex-shrink: 0;
  border-color: rgba(20, 20, 20, 0.20000000298023224);
  border-style: solid;
  border-width: 1px;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 1);
}
.jobs-list-text142 {
  color: rgba(20, 20, 20, 0.5);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  text-align: left;
  font-family: DM Sans;
  font-weight: 400;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.jobs-list-button10 {
  gap: 8px;
  width: 202px;
  display: flex;
  padding: 8px 20px 8px 16px;
  box-sizing: content-box;
  align-items: center;
  flex-shrink: 0;
  border-radius: 4px;
  justify-content: center;
  background-color: rgba(53, 117, 226, 1);
}
.jobs-list-text144 {
  color: var(--dl-color-default-white);
  height: auto;
  font-size: 16px;
  font-style: Medium;
  text-align: left;
  font-family: DM Sans;
  font-weight: 500;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.jobs-list-frame186 {
  gap: 16px;
  width: 250px;
  display: flex;
  padding: 24px;
  overflow: hidden;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.029999999329447746) ;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(20, 20, 20, 0.05000000074505806);
  border-style: solid;
  border-width: 1px;
  border-radius: 8px;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 1);
}
.jobs-list-frame364 {
  gap: 16px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.jobs-list-text146 {
  color: rgba(20, 20, 20, 1);
  height: auto;
  font-size: 20px;
  font-style: Bold;
  text-align: left;
  font-family: DM Sans;
  font-weight: 700;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.jobs-list-frame372 {
  gap: 16px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.jobs-list-text148 {
  color: rgba(20, 20, 20, 0.800000011920929);
  height: auto;
  font-size: 14px;
  align-self: stretch;
  font-style: Regular;
  text-align: left;
  font-family: DM Sans;
  font-weight: 400;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.jobs-list-button11 {
  gap: 8px;
  display: flex;
  padding: 8px 20px 8px 16px;
  align-self: stretch;
  box-sizing: content-box;
  align-items: center;
  flex-shrink: 0;
  border-radius: 4px;
  justify-content: center;
  background-color: rgba(53, 117, 226, 1);
}
.jobs-list-text150 {
  color: var(--dl-color-default-white);
  height: auto;
  font-size: 16px;
  font-style: Medium;
  text-align: left;
  font-family: DM Sans;
  font-weight: 500;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.jobs-list-container6 {
  flex: 0 0 auto;
  width: 100%;
  height: 100px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.jobs-list-footer {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: space-between;
}
.jobs-list-image {
  height: 2rem;
  object-fit: cover;
}
.jobs-list-icon-group {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.jobs-list-icon {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.jobs-list-icon2 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.jobs-list-icon4 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
@media(max-width: 1600px) {
  .jobs-list-container {
    align-content: center;
    background-color: #ffffff;
  }
  .jobs-list-navbar {
    top: 0px;
    flex: 1;
    left: 0px;
    right: 0px;
    width: 1600px;
    height: 72px;
    margin: auto;
    padding-top: 0px;
    border-color: var(--dl-color-gray-black);
    border-width: 1px;
    border-radius: var(--dl-radius-radius-radius4);
    padding-bottom: 0px;
    background-color: #000000;
  }
  .jobs-list-container1 {
    align-self: stretch;
    margin-top: var(--dl-space-space-halfunit);
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .jobs-list-text {
    color: #ffffff;
    font-size: 24px;
    text-align: center;
    font-family: DM Sans;
    font-weight: bold;
    line-height: 150%;
    text-transform: capitalize;
    text-decoration: none;
  }
  .jobs-list-text001 {
    color: #ffffff;
    font-size: 18px;
    text-align: center;
    font-family: DM Sans;
    font-weight: 400;
    line-height: 150%;
    text-transform: capitalize;
    text-decoration: none;
  }
  .jobs-list-text002 {
    color: #ffffff;
    font-size: 18px;
    text-align: center;
    font-family: DM Sans;
    line-height: 150%;
    text-transform: capitalize;
    text-decoration: none;
  }
  .jobs-list-text003 {
    color: #ffffff;
    font-size: 18px;
    text-align: center;
    text-transform: capitalize;
  }
  .jobs-list-text004 {
    color: #ffffff;
    font-size: 18px;
    text-align: center;
    font-family: DM Sans;
    font-weight: 400;
    line-height: 150%;
    text-transform: capitalize;
    text-decoration: none;
  }
  .jobs-list-button {
    background-color: #3d3d3d;
  }
  .jobs-list-text005 {
    color: #ffffff;
    font-size: 18px;
    text-align: center;
    text-transform: capitalize;
  }
  .jobs-list-text007 {
    font-size: 18px;
    text-align: center;
    font-weight: bold;
    text-transform: capitalize;
  }
  .jobs-list-container2 {
    height: 177px;
    margin: var(--dl-space-space-fourunits);
    position: relative;
    margin-top: 0px;
    align-items: center;
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 0px;
    flex-direction: column;
    justify-content: flex-end;
    background-color: var(--dl-color-default-white);
  }
  .jobs-list-text009 {
    color: rgb(0, 0, 0);
    position: static;
    font-size: 18px;
    align-self: center;
    margin-top: 0px;
    text-align: center;
    font-family: Inter;
    font-weight: 400;
    line-height: 150%;
    padding-top: 0px;
    text-decoration: none;
  }
  .jobs-list-text010 {
    left: 0px;
    color: var(--dl-color-gray-black);
    right: 0px;
    bottom: 46px;
    margin: auto;
    position: absolute;
    font-size: 56px;
    align-self: center;
    text-align: center;
    font-family: Inter;
    font-weight: 700;
    line-height: 150%;
    text-decoration: none;
  }
  .jobs-list-text011 {
    margin-top: var(--dl-space-space-unit);
    text-align: center;
  }
  .jobs-list-text012 {
    margin-top: var(--dl-space-space-unit);
    text-align: center;
  }
  .jobs-list-text013 {
    margin-top: var(--dl-space-space-unit);
    text-align: center;
  }
  .jobs-list-container3 {
    width: auto;
    align-self: center;
  }
  .jobs-list-frame9 {
    width: 536px;
  }
  .jobs-list-frame14 {
    width: 233px;
  }
  .jobs-list-container4 {
    position: relative;
    margin-bottom: 1279px;
  }
  .jobs-list-content {
    top: 0px;
    left: 0px;
    right: 0px;
    width: auto;
    height: 1382px;
    margin: auto;
    position: absolute;
    align-items: center;
    padding-top: 0px;
    padding-left: 64px;
    padding-right: 64px;
    padding-bottom: 0px;
    justify-content: center;
    background-color: rgba(217, 217, 217, 0.14);
  }
  .jobs-list-filter {
    background-color: #ffffff;
  }
  .jobs-list-text020 {
    color: #000000;
  }
  .jobs-list-text022 {
    color: rgb(0, 0, 0);
    font-size: 18px;
    font-family: DM Sans;
    font-weight: 500;
    line-height: 150%;
    text-decoration: none;
  }
  .jobs-list-textinput {
    align-self: stretch;
    border-color: var(--dl-color-gray-700);
  }
  .jobs-list-text025 {
    color: #000000;
  }
  .jobs-list-text043 {
    color: rgb(0, 0, 0);
    font-size: 18px;
    font-family: DM Sans;
    font-weight: 500;
    line-height: 150%;
    text-decoration: none;
  }
  .jobs-list-text044 {
    color: rgb(57, 63, 71);
    font-size: 16px;
    font-family: DM Sans;
    font-weight: 400;
    line-height: 150%;
    text-decoration: none;
  }
  .jobs-list-text045 {
    color: rgb(0, 0, 0);
  }
  .jobs-list-text046 {
    color: rgb(57, 63, 71);
    font-size: 16px;
    font-family: DM Sans;
    font-weight: 400;
    line-height: 150%;
    text-decoration: none;
  }
  .jobs-list-text047 {
    color: #000000;
  }
  .jobs-list-text049 {
    color: rgb(57, 63, 71);
    font-size: 16px;
    font-family: DM Sans;
    font-weight: 400;
    line-height: 150%;
    text-decoration: none;
  }
  .jobs-list-text050 {
    color: rgb(57, 63, 71);
    font-size: 16px;
    font-family: DM Sans;
    font-weight: 400;
    line-height: 150%;
    text-decoration: none;
  }
  .jobs-list-text051 {
    color: rgb(57, 63, 71);
    font-size: 16px;
    font-family: DM Sans;
    font-weight: 400;
    line-height: 150%;
    text-decoration: none;
  }
  .jobs-list-text052 {
    color: rgb(57, 63, 71);
  }
  .jobs-list-text053 {
    color: rgb(57, 63, 71);
    font-size: 16px;
    font-family: DM Sans;
    font-weight: 400;
    line-height: 150%;
    text-decoration: none;
  }
  .jobs-list-text054 {
    color: #000000;
  }
  .jobs-list-text062 {
    color: #000000;
  }
  .jobs-list-frame23 {
    position: relative;
  }
  .jobs-list-clock {
    width: 16px;
    height: 16px;
  }
  .jobs-list-clock1 {
    width: 16px;
    height: 16px;
  }
  .jobs-list-text090 {
    color: rgba(20, 20, 20, 0.698);
    font-size: 16px;
    font-family: DM Sans;
    font-weight: 400;
    line-height: 150%;
    text-decoration: none;
  }
  .jobs-list-clock2 {
    width: 16px;
    height: 16px;
  }
  .jobs-list-text101 {
    color: rgba(20, 20, 20, 0.698);
    font-size: 16px;
    font-family: DM Sans;
    font-weight: 400;
    line-height: 150%;
    text-decoration: none;
  }
  .jobs-list-clock3 {
    width: 16px;
    height: 16px;
  }
  .jobs-list-text112 {
    color: rgba(20, 20, 20, 0.698);
    font-size: 16px;
    font-family: DM Sans;
    font-weight: 400;
    line-height: 150%;
    text-decoration: none;
  }
  .jobs-list-clock4 {
    width: 16px;
    height: 16px;
  }
  .jobs-list-text123 {
    color: rgba(20, 20, 20, 0.698);
    font-size: 16px;
    font-family: DM Sans;
    font-weight: 400;
    line-height: 150%;
    text-decoration: none;
  }
  .jobs-list-clock5 {
    width: 0px;
    height: 0px;
  }
  .jobs-list-clock6 {
    width: 16px;
    height: 16px;
  }
  .jobs-list-text134 {
    color: rgba(20, 20, 20, 0.698);
    font-size: 16px;
    font-family: DM Sans;
    font-weight: 400;
    line-height: 150%;
    text-decoration: none;
  }
  .jobs-list-frame16 {
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.5);
    background-color: #ffffff;
  }
  .jobs-list-text140 {
    color: rgb(0, 0, 0);
    font-size: 20px;
    font-family: DM Sans;
    font-weight: 700;
    line-height: 150%;
    text-decoration: none;
  }
  .jobs-list-text141 {
    color: rgba(0, 0, 0, 0.8);
  }
  .jobs-list-button10 {
    width: auto;
    align-self: stretch;
  }
  .jobs-list-frame186 {
    display: none;
  }
  .jobs-list-container6 {
    position: relative;
  }
  .jobs-list-footer {
    top: 0px;
    left: 0px;
    right: 0px;
    margin: auto;
    position: absolute;
    align-self: stretch;
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
}
@media(max-width: 1200px) {
  .jobs-list-navbar {
    flex: 1;
    width: 1200px;
  }
  .jobs-list-content {
    flex: 1;
    width: auto;
  }
}
@media(max-width: 767px) {
  .jobs-list-footer {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .jobs-list-text152 {
    text-align: center;
    margin-left: var(--dl-space-space-unit);
    margin-right: var(--dl-space-space-unit);
  }
}
@media(max-width: 479px) {
  .jobs-list-footer {
    padding: var(--dl-space-space-unit);
    flex-direction: column;
  }
  .jobs-list-image {
    margin-bottom: var(--dl-space-space-unit);
  }
  .jobs-list-text152 {
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-unit);
  }
}
